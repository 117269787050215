import { Component, OnInit, Inject, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, Router, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { MatSnackBar, MatSnackBarHorizontalPosition, MatSnackBarVerticalPosition, MAT_SNACK_BAR_DATA, MatSnackBarRef } from "@angular/material/snack-bar";
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpParams } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { PdfJsViewerModule } from "ng2-pdfjs-viewer";

import { MaterialModule } from '@shared/material.module';
import { Clipboard } from '@angular/cdk/clipboard';
import { PreviewLinkComponent } from '@shared/preview-link/preview-link.component';
import { ReadMoreComponent } from '@shared/read-more/read-more.component';
import { ConfirmDialogComponent } from '@shared/confirm-dialog/confirm-dialog.component';
import { LocalizedDatePipe } from '@shared/language/localized-date.pipe';
import { AccountService } from '@modules/account/services/account.service';
import { MetadataService } from '@core/services/metadata.service';
import { DeviceInfoService } from '@core/services/device-info.service';
import { ImagePipe } from '@core/util/image.pipe';
import { VideoPipe } from '@core/util/video.pipe';
import { DocumentPipe } from '@core/util/document.pipe';
import { SafeResourceUrlPipe } from '@core/util/safeResourceUrl.pipe';
import { Credential } from '@modules/account/models/credential.model';
import { MediaComponent } from '@modules/post/components/media/media.component';
import { ReportComponent } from '@modules/report/components/report.component';
import { PostService } from '@modules/post/services/post.service';
import { ListLikesComponent } from '@modules/like/components/list-likes.component';
import { ListSharesComponent } from '@modules/share/components/list-shares.component';
import { ListCommentsComponent } from '@modules/comment/components/list-comments.component';
import { LikeIconComponent } from '@modules/like/components/like-icon.component';
import { ShareIconComponent } from '@modules/share/components/share-icon.component';
import { CommentIconComponent } from '@modules/comment/components/comment-icon.component';
import { ProcessPostComponent } from './process-post.component';
import { environment } from '@environments/environment';

@Component({
  selector: '[xa-post]',
  templateUrl: './post.component.html',
  styleUrls: ['./post.component.scss'],
  standalone: true,
  imports: [ImagePipe, VideoPipe, DocumentPipe, LocalizedDatePipe, LikeIconComponent, ShareIconComponent, CommentIconComponent,
    PreviewLinkComponent, ReadMoreComponent, ListCommentsComponent, TranslateModule, FontAwesomeModule, MaterialModule,
    RouterModule, CommonModule, PdfJsViewerModule ]
})
export class PostComponent implements OnInit {
  @Input() post: any;
  @Input() forums: any;
  @Input() origin: any;
  @Output() postToRemove = new EventEmitter<{post: any}>();
  credential: Credential;
  expansionId: number = -1;
  durationInSeconds = 10;
  horizontalPosition: MatSnackBarHorizontalPosition = 'start';
  verticalPosition: MatSnackBarVerticalPosition = 'bottom';

  constructor(
    private route: ActivatedRoute,
    private dialog: MatDialog,
    private accountService: AccountService,
    private postService: PostService,
    private translate: TranslateService,
    private router: Router,
    public deviceInfo: DeviceInfoService,
    private metadataService: MetadataService,
    private clipboard: Clipboard,
    private snackBar: MatSnackBar
  ) {}

  ngOnInit(): void {
    this.credential = this.accountService.credentialValue;
    const seo = this.credential ? false : true;

    if (!this.post && this.route.snapshot.params.id) {  // from clip board or search engine
      let params = new HttpParams();
      if (this.credential) {
        params = params.append('my', this.credential.profileId);
      }
      this.postService.getPost(this.route.snapshot.params.id, seo, params).subscribe((resp) => {
        this.post = resp.data;
        this.textEnriched(this.post);
        this.origin = "url";
        if (!this.credential) {
          this.metadataService.updateMetadata({
            //title: this.event.title,
            // description: this.translate.instant('post.meta.description'),
            imageRelativeUrl: '/assets/images/background.jpg',
            type: "post"
          });
        }
      })
    }
    else {
      this.textEnriched(this.post);
    }
  }

  textEnriched(post: any): void {
    // extract links, eg https://youtube.com/watch?v=0oPAkkHXYHs&t=541s with leading blank char
    let regex = /(\s|&nbsp;|<br\s*\/?>|$)((https?:\/\/)?(([a-zäöüßA-ZÄÖÜ0-9-_+:]{2,})\.)?(([a-zäöüßA-ZÄÖÜ0-9-_+:]{2,})\.)([a-zäöüßA-ZÄÖÜ]{2,})[\/[a-zäöüßA-ZÄÖÜ0-9-_+.?&=:]{1,}]{0,})/g;
    this.post.textEnriched = post.text.replace(regex, function(m:any, t1:any, t2:any) {
      let t3 = t2.startsWith("http://") || t2.startsWith("https://") ? t2 : `http://${t2}`;
      return `${t1}<a href="${t3}" target="_blank">${t2}</a>`;
    });
  }

  editPost(post: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.width = "100%";
    dialogConfig.maxWidth = "600px";
    dialogConfig.data = {
      post: post,
      state: 'edit',
      origin: this.origin
    }
    if (this.forums) {
      dialogConfig.data.forums = this.forums;
    }

    const dialogRef = this.dialog.open(ProcessPostComponent,
      dialogConfig);

    dialogRef.afterClosed().subscribe(editedPost => {
      if (editedPost) {
        post.subject = editedPost.subject,
        post.text = editedPost.text;
        post.textEnriched = post.text;
        if (post.origin=='feed') {
          post.visibility = editedPost.visibility;
        }
        if (post.origin=='group') {
          post.forum = post.forums.filter((forum: any) => forum.id==Number(editedPost.forumId))[0];
        }
      }
    });
  }

  copyPostLink(post: any): void {
    const url = `${environment.appUrl}/posts/${post.id}`;
    this.clipboard.copy(url);
    this.snackBar.openFromComponent(PostSnackBarComponent, {
        data: post.id,
        duration: this.durationInSeconds * 1000,
        horizontalPosition: this.horizontalPosition,
        verticalPosition: this.verticalPosition
      }).onAction().subscribe(() => this.router.navigate(['posts', post.id]));
  }

  reportPost(post: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.width = '100%';
    dialogConfig.maxWidth = '800px';
    dialogConfig.data = {
      objectId: post.id,
      objectType: 'Post',
    };

    const dialogRef = this.dialog.open(ReportComponent,
      dialogConfig);
  }

  deletePost(post: any): any {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.width = "100%";
    dialogConfig.maxWidth = "400px";
    dialogConfig.autoFocus = false;
    dialogConfig.data = {
      title: this.translate.instant('post.delete'),
      message: this.translate.instant('post.deleteWarning', {
        subject: post.subject
      }),
      button: this.translate.instant('post.button.delete')
    };
    const dialogRef = this.dialog.open(ConfirmDialogComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(dialogResult => {
      if (dialogResult) {
        let params = new HttpParams();
        params = params.append('id', post.id);
        this.postService.deletePost(params).subscribe((data) => {
          this.postToRemove.emit(this.post);
        });
      }
    });
  }

  viewImages(images: any): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.width = "100%";
    dialogConfig.maxWidth = "800px";
    dialogConfig.data = {
      images: images
    };

    const dialogRef = this.dialog.open(MediaComponent,
      dialogConfig);
  }

  isCommentsChange() {
  }

  setComment(postId: number): void {
    this.expansionId = postId;
  }

  isLikedChange(isLiked: any, post: any) {
    if (isLiked) { // isLiked = true
      post.reaction.numLikes = post.reaction.numLikes + 1;
      post.reaction.isLiked = true;
    }
    else { // isLiked = false
      post.reaction.numLikes = post.reaction.numLikes - 1;
      post.reaction.isLiked = true;
    }
  }

  listLikes(id: number, type: string): void {
    this.expansionId = id;
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.width = "100%";
    dialogConfig.maxWidth = "500px";
    dialogConfig.data = {
      likedId: id,
      likedType: type
    };
    const dialogRef = this.dialog.open(ListLikesComponent,
      dialogConfig);
  }

  isSharedChange(event: any, post: any) {
    post.reaction.numShares = post.reaction.numShares + 1;
  }

  listShares(postId: number): void {
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.width = "100%";
    dialogConfig.maxWidth = "500px";
    dialogConfig.data = {
      sharedId: postId,
      sharedType: 'post'
    };
    const dialogRef = this.dialog.open(ListSharesComponent,
      dialogConfig);
  }

  isEditable(post: any): any {
    return (this.credential && post.author.id==this.credential.profileId) ||
      (this.credential && post.group && post.group.member && post.group.member.filter((member: any) => member.profileId==this.credential.profileId).length===1) ||
      (this.credential && post.organization && post.organization.member && post.organization.member.filter((member: any) => member.profileId==this.credential.profileId).length===1)
      ? true : false;
  }

  firstAndLastName(profile: any): string {
    return profile.firstName!.concat(" ").concat(profile.lastName!);
  }
}

@Component({
  selector: 'post-snackbar',
  templateUrl: 'post-snackbar.component.html',
  standalone: true,
  imports: [RouterModule, TranslateModule, FontAwesomeModule, MaterialModule, CommonModule],
  styles: [`
    button:hover{
      background-color: rgba(255, 255, 255, 0.08);
    }
    .snackbar-container{
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 20px;
        opacity: 1;
    }
    .snackbar-link {
      color: rgb(10, 102, 194) !important;
    }
    .button-container{
        flex-shrink: 0;
        margin: -8px -8px -8px 8px;
    }
  `],
})
export class PostSnackBarComponent {
  constructor(
    private router: Router,
    public snackBarRef: MatSnackBarRef<PostSnackBarComponent>,
    @Inject(MAT_SNACK_BAR_DATA) public data: any
  ){}

  forward() {
    this.snackBarRef.dismiss();
    this.router.navigate(['posts/' + this.data ]);
  }
}
