import { Injectable, NgZone } from '@angular/core';
import RecordRTC from 'recordrtc';
import { DateTime, Duration } from 'luxon';
import { Observable, Subject } from 'rxjs';
import ysFixWebmDuration from 'fix-webm-duration';

@Injectable({providedIn: 'root'})
export class MediaRecordingService {
  private stream: any;
  private recorder: any;
  private interval: any;
  private startTime: any;
  private _recorded = new Subject<Blob>();
  private _recordedTime = new Subject<string>();
  private _recordingFailed = new Subject<string>();

  getRecordedBlob(): Observable<Blob> {
    return this._recorded.asObservable();
  }

  getRecordedTime(): Observable<string> {
    return this._recordedTime.asObservable();
  }

  recordingFailed(): Observable<string> {
    return this._recordingFailed.asObservable();
  }

  startRecording() {
    if (this.recorder) {
      // It means recording is already started or it is already recording something
      return;
    }

    this._recordedTime.next('0:00');
    navigator.mediaDevices
      .getUserMedia({ audio: true })
      .then((s) => {
        this.stream = s;
        this.record();
      })
      .catch((error) => {
        this._recordingFailed.next("null");
      });
  }

  abortRecording() {
    this.stopMedia();
  }

  private record() {
    this.recorder = new RecordRTC.MediaStreamRecorder(this.stream, {
      type: 'video',
      mimeType: 'video/mp4',
      audioBitsPerSecond: 64 * 1000, // 64 kbit/s
      // audioBitsPerSecond: 128 * 1000, // 128 kbit/s
      videoBitsPerSecond: 2 * 1000 * 1000 // 2 Mbit/s
    });
    this.recorder.record();
    this.startTime = DateTime.now();
    this.interval = setInterval(() => {
      const currentTime = DateTime.now();
      const diffTime = currentTime.diff(this.startTime, ['minutes', 'seconds']);
      if (diffTime.minutes >= 5) {
        this.stopRecording();
      }
      const time =
        this.toString(diffTime.minutes, 'm') +
        ':' +
        (this.toString(diffTime.seconds, 's'));
      this._recordedTime.next(time);
    }, 500);
  }

  private toString(value: any, obj: any) {
    let val = value;
    if (!value) {
      val = '0';
    }
    if (obj == 's') {
      if (value < 10) {
        val = '0' + value;
      }
      val = val.toString().split('.')[0];
    }
    return val;
  }

  stopRecording() {
    if (this.recorder) {
      this.recorder.stop(
        (blob:any) => {
          if (this.startTime) {
            const duration = this.startTime.diff(DateTime.now(), ["minutes", "seconds"])
            ysFixWebmDuration(blob, duration, (fixedBlob:any) => {
              this.stopMedia();
              this._recorded.next(fixedBlob);
            });
          }
        },
        () => {
          this.stopMedia();
          this._recordingFailed.next("null");
        }
      );
    }
  }

  private stopMedia() {
    if (this.recorder) {
      this.recorder = null;
      clearInterval(this.interval);
      this.startTime = null;
      if (this.stream) {
        this.stream.getAudioTracks().forEach((track:any) => track.stop());
        this.stream = null;
      }
    }
  }
}
