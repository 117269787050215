import { Component, OnInit, Inject } from '@angular/core';
import { FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA, MatDialogRef } from "@angular/material/dialog";
import { MatSnackBar } from '@angular/material/snack-bar';

import { AccountService } from '@modules/account/services/account.service';
import { Credential } from '@modules/account/models/credential.model';
import { ReportService } from '@modules/report/services/report.service';
import { Report } from '@modules/report/models/report.model';

@Component({
  selector: 'xa-report',
  templateUrl: './report.component.html',
  styleUrls: ['./report.component.scss']
})
export class ReportComponent implements OnInit {
  isSubmitted: boolean = false;
  credential: Credential;
  form: any;

  constructor(
    private formBuilder: UntypedFormBuilder,
    private reportService: ReportService,
    private accountService: AccountService,
    private dialogRef: MatDialogRef<ReportComponent>,
    @Inject(MAT_DIALOG_DATA) data: {objectId: number, objectType: string},
    private snackBar: MatSnackBar
  ) {
    this.handleFormData(data);
  }

  handleFormData(data: any) : void {
	  this.form = this.formBuilder.group({
      issue: ['', [Validators.required]],
      comment: ['', [Validators.required, Validators.minLength(50)]],
      objectId: [data.objectId],
      objectType: [data.objectType],
      reportedAt: [new Date()],
    });
	}

  ngOnInit(): void {
    this.credential = this.accountService.credentialValue;
  }

  saveReport(): any {
    if (this.form.valid) {
      this.isSubmitted = true;
      let reportObj:any = {
        issue: this.form.value.issue[0],
        comment: this.form.value.comment,
        objectId: this.form.value.objectId,
        objectType: this.form.value.objectType,
        reportedAt: this.form.value.reportedAt,
        reportedById: this.credential.profileId
      }
      this.reportService.saveReport(reportObj).subscribe(data => {
        this.isSubmitted = false;
        this.snackBar.open('Vielen Dank für Ihre Meldung. Wir werden diese zügig prüfen', 'OK', {
          duration: 3000
        });
        this.dialogRef.close(this.form.value);
      });
    }
  }
}
