import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { Report } from '@modules/report/models/report.model';
import { ApiService } from '@core/services/api.service';

@Injectable({
  providedIn: 'root'
})

export class ReportService {
  constructor(
    private apiService: ApiService
  ) { }

  getReports(params : HttpParams): Observable < any > {
    return this.apiService.get('/reports', params);
  }

  saveReport(report: Report): Observable<any> {
    return this.apiService.post('/reports/save', report);
  }

  deleteReport(params : HttpParams): Observable<any> {
    return this.apiService.delete('/reports/delete/', params);
  }
}
