<ng-container *ngIf="data | async as error">
  <div class="flex-layout">
    <div class="panel-container">
      <ng-container *ngIf="error.code==404">
        <div class="error-image">
          <img src="/assets/images/errors/magnifying-glass-small.jpg" alt="page not found" />
        </div>
        <div class="error-message">{{'error.http.404' | translate}}</div>
      </ng-container>
      <ng-container *ngIf="error.code==504">
        <div class="error-image">
          <img src="/assets/images/errors/connection-small.jpg" alt="no connection" />
        </div>
        <div class="error-message">{{'error.http.504' | translate}}</div>
      </ng-container>
      <ng-container *ngIf="error.code!=404 && error.code!=504">
        <div class="error-image">
          <img src="/assets/images/errors/question-mark-small.jpg" alt="application error" />
        </div>
        <div class="error-message">{{'error.http.500' | translate}}</div>
      </ng-container>
      <div class="to-start">
        <a routerLink="/">{{'error.toStart' | translate}}</a>
      </div>
      <hr>
      <div class="error-code">{{ error.code }} {{ error.message }}</div>
    </div>
  </div>
</ng-container>
