/* eslint-disable @typescript-eslint/no-unsafe-return */
import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Organization } from '@modules/organization/models/organization.model';
import { ApiService } from '@core/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class OrganizationService {

  constructor(
    private apiService: ApiService
  ) { }

  getOrganizations(params : HttpParams): Observable<any> {
    return this.apiService.get('/organizations', params);
  }

  getOrganization(id: number, seo: boolean, params?: HttpParams): Observable<any> {
    const url = seo == true ? `/organizations/seo/${id}` : `/organizations/${id}`;
    return this.apiService.get(url, params);
  }

  saveOrganization(organization: Organization): Observable<any> {
    return this.apiService.post('/organizations/save', organization);
  }


  getMembersOfOrganization(slug: string, params : HttpParams): Observable<any> {
    const url = `/organizations/${slug}/members`;
	  return this.apiService.get(url, params);
  }

  getPostsOfOrganization(slug: string, params : HttpParams): Observable<any> {
    const url = `/organizations/${slug}/posts`;
	  return this.apiService.get(url, params);
  }

  getEventsOfOrganization(slug: string, params : HttpParams): Observable<any> {
   const url = `/organizations/${slug}/events`;
   return this.apiService.get(url, params);
  }

  getContactsToInvite(slug: string, params: any): Observable<any> {
    const url = `/organizations/${slug}/contacts-to-invite`;
    return this.apiService.get(url, params);
  }

  sendInvitation(invitation: any): Observable<any> {
    return this.apiService.post('/organizations/invite', invitation);
  }

  saveLocation(location: any): Observable<any> {
    return this.apiService.post('/organizations/location/save', location);
  }

  deleteLocation(params : HttpParams): Observable<any> {
    return this.apiService.delete('/organizations/location/delete/', params);
  }

  lookupCountry(params: HttpParams): Observable<any> {
    return this.apiService.get('/geo/countries', params);
  }

  lookupPostalCode(params: HttpParams): Observable<any> {
    return this.apiService.get('/geo/postalcode', params);
  }
}
