<div class="panel">
  <button class="start-button" *ngIf="!isMediaRecording && !mediaBlobUrl" (click)="startMediaRecording()">
    <fa-icon icon="play"></fa-icon>
  </button>
  <button class="stop-button" *ngIf="isMediaRecording && !mediaBlobUrl" (click)="stopMediaRecording()">
    <fa-icon icon="pause"></fa-icon>
  </button>
  <div class="media-timer" *ngIf="isMediaRecording && !mediaBlobUrl">
    {{ mediaRecordedTime }}
  </div>
  <div xa-media-player [src]="mediaBlobUrl" *ngIf="!isMediaRecording && mediaBlobUrl"></div>
  <button (click)="clearMediaRecordedData()" class="action-button-rounded" *ngIf="!isMediaRecording && (mediaBlobUrl || !mediaBlobUrl)">
    <fa-icon icon="xmark"></fa-icon>
  </button>
  <button (click)="sendMessage()" class="action-button-rounded" *ngIf="!isMediaRecording && mediaBlobUrl">
    <fa-icon icon="paper-plane"></fa-icon>
  </button>
</div>
