import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ApiService } from '@core/services/api.service';
// import { Visit } from '@modules/visit/models/visit.model';

@Injectable({
  providedIn: 'root'
})

export class LikeService {
  constructor(
    private apiService: ApiService
  ) { }

  getLikes(params : HttpParams): Observable<any> {
    return this.apiService.get('/likes', params);
  }

  saveLike(like: any): Observable<{}> {
    return this.apiService.post('/likes/save', like);
  }
}
