import { NgModule, InjectionToken } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';

// import { ErrorRoute } from './layouts/error/error.route';
// import { HeaderRoute } from './layouts/header/header.route';

import { LoginComponent } from '@modules/login/components/login.component';
import { LogoutComponent } from '@modules/logout/components/logout.component';
import { NetworkComponent } from '@modules/network/components/network.component';
import { ListVisitsComponent } from '@modules/visit/components/list-visits.component';
import { ErrorhandlerComponent, ErrorResolver, WrongRouteComponent } from '@modules/errorhandler';

const routes: Routes = [{
    path: '',
    loadChildren: () => import('@modules/start/start.module').then(m => m.StartModule)
  },
  {
    path: 'login',
    component: LoginComponent
  },
  {
    path: 'logout',
    component: LogoutComponent
  },
  {
    path: 'legal',
    loadChildren: () => import('@modules/legal/legal.module').then(m => m.LegalModule)
  },
  {
    path: 'help',
    loadChildren: () => import('@modules/help/help.module').then(m => m.HelpModule)
  },
  {
    path: 'network',
    loadChildren: () => import('@modules/network/network.module').then(m => m.NetworkModule)
  },
  {
    path: 'profile',
    loadChildren: () => import('@modules/profile/profile.module').then(m => m.ProfileModule)
  },
  {
    path: 'messages',
    loadChildren: () => import('@modules/message/message.module').then(m => m.MessageModule)
  },
  {
    path: 'notifications',
    loadChildren: () => import('@modules/notification/notification.module').then(m => m.NotificationModule)
  },
  {
    path: 'visits',
    component: ListVisitsComponent
  },
  {
    path: 'posts',
    loadChildren: () => import('@modules/post/post.module').then(m => m.PostModule)
  },
  {
    path: 'events',
    loadChildren: () => import('@modules/event/event.module').then(m => m.EventModule)
  },
  {
    path: 'groups',
    loadChildren: () => import('@modules/group/group.module').then(m => m.GroupModule)
  },
  {
    path: 'organizations',
    loadChildren: () => import('@modules/organization/organization.module').then(m => m.OrganizationModule)
  },
  {
    path: 'jobs',
    loadChildren: () => import('@modules/job/job.module').then(m => m.JobModule)
  },
  {
    path: 'products',
    loadChildren: () => import('@modules/product/product.module').then(m => m.ProductModule)
  },
  {
    path: 'admin',
    loadChildren: () => import('@modules/admin/admin.module').then(m => m.AdminModule)
  },
  {
    path: 'preference',
    loadChildren: () => import('@modules/preference/preference.module').then(m => m.PreferenceModule)
  },
  {
    path: 'error',
    component: ErrorhandlerComponent,
    resolve: {
      errorData: ErrorResolver
    },
  },
  {
    path: '',
    loadChildren: () => import('@modules/account/account.module').then(m => m.AccountModule)
  },
  {
    path: '**',
    component: WrongRouteComponent
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    initialNavigation: 'enabledNonBlocking',
    paramsInheritanceStrategy: 'always',
    scrollPositionRestoration: 'enabled',
    anchorScrolling: 'enabled',
    onSameUrlNavigation: 'reload',
  })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
