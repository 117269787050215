import { Component, OnInit, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule, ReactiveFormsModule, FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { MatRadioChange } from '@angular/material/radio';
import { MatSelectChange } from '@angular/material/select';
import { HttpParams } from '@angular/common/http';
import { forkJoin } from "rxjs";
import { TranslateModule, TranslateService } from '@ngx-translate/core';

import { MaterialModule } from '@shared/material.module';
import { Settings } from '@modules/post/models/settings.model';
import { AccountService } from '@modules/account/services/account.service';
import { GroupService } from '@modules/group/services/group.service';
import { ForumService } from '@modules/post/services/forum.service';
import { OrganizationService } from '@modules/organization/services/organization.service';
import { Logger } from '@shared/logger/logger.service';

@Component({
  selector: '[xa-post-settings]',
  templateUrl: './post-settings.component.html',
  styleUrls: ['./post-settings.component.scss'],
  standalone: true,
  imports: [FormsModule, ReactiveFormsModule, TranslateModule, FontAwesomeModule, MaterialModule, CommonModule]
})
export class PostSettingsComponent implements OnInit {
  @Input() settings: Settings;
  @Output() selectedSettings = new EventEmitter();
  credential: any;
  groups: any;
  forums: any;
  organizations: any;
  form: any;
  viewable: string = 'settings';

  constructor(
    private formBuilder: UntypedFormBuilder,
    public translate: TranslateService,
    private accountService: AccountService,
    private groupService: GroupService,
    private forumService: ForumService,
    private organizationService: OrganizationService,
    private logger: Logger
  ) {
    this.form = this.formBuilder.group({
      visibility: [''],
      groupId: [''],
      organizationId: [''],
      forumId: ['']
    });
  }

  ngOnInit(): void {
    this.credential = this.accountService.credentialValue;
    this.form.controls.visibility.setValue(this.settings.visibility);
    let params = new HttpParams();
    params = params.append('my', this.credential.profileId);
    params = params.append('filter', 'yours');
    forkJoin([this.groupService.getGroups(params),
        this.organizationService.getOrganizations(params)
      ])
      .subscribe(([respGroups, respOrganizations]) => {
          this.groups = respGroups.data.groups;
          this.organizations = respOrganizations.data.organizations;
        },
        error => {
          this.logger.log(error);
        }
      )
  }

  change(event: MatRadioChange) {
    if (event.value == 'g') {
      this.viewable='select-group';
    }
    if (event.value == 'o') {
      this.viewable='select-organization';
    }
  }

  selectGroup(event: MatRadioChange) {
    this.settings.groupId = this.form.value.groupId;
    let group = this.groups.filter((group:any) => group.id === this.form.value.groupId)[0];
    this.settings.name = group.name;
    let params = new HttpParams();
    params = params.append('my', this.credential.profileId);
    params = params.append('groupId', group.id);
    this.forumService.getForums(params).subscribe((resp:any) => {
      this.forums = resp.data.forums
    });
  }

  selectForum(event: MatSelectChange) {
    this.settings.forumId = this.form.value.forumId;
  }

  selectOrganization(event: MatRadioChange) {
    this.settings.organizationId = this.form.value.organizationId;
    let organization = this.organizations.filter((organization:any) => organization.id === this.form.value.organizationId)[0];
    this.settings.name = organization.name;
  }

  save() {
    this.settings.visibility = this.form.value.visibility;
    this.selectedSettings.emit(this.settings)
  }

  back() {
    this.selectedSettings.emit(this.settings)
  }

  backToSettings() {
    this.form.controls.visibility.setValue('c');
    this.form.controls.groupId.setValue(null);
    this.form.controls.organizationId.setValue(null);
    this.form.controls.forumId.setValue(null);
    this.forums = null;
    this.viewable='settings';
  }

  getListHeight() {
    return this.settings.groupId ? 200 : 240;
  }
}
