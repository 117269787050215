<div class="panel-container overlay">
  <div class="header participant-container" *ngIf="participant">
    <a routerLink="/profile/{{participant.slug}}">
      <img class="avatar" [src]="participant.photoImage | image: 'avatar':'s1'" title="{{firstAndLastName(participant)}}" alt="{{firstAndLastName(participant)}}" />
    </a>
    <div class="message-panel flex-child">
      <a routerLink="/profile/{{participant.slug}}">
        <div class="name-line">{{firstAndLastName(participant)}}</div>
        <div>{{participant.slogan}}</div>
      </a>
    </div>
    <mat-menu #appMenu="matMenu">
      <ng-template matMenuContent>
        <a mat-menu-item (click)="report()">{{'message.report' | translate}}</a>
        <a mat-menu-item (click)="archive()">{{'message.archive' | translate}}</a>
        <a mat-menu-item (click)="delete()">{{'message.delete' | translate}}</a>
      </ng-template>
    </mat-menu>
    <button class="action-button-rounded" [matMenuTriggerFor]="appMenu">
      <fa-icon icon="ellipsis-h"></fa-icon>
    </button>
    <button class="action-button-rounded" (click)="remove(participant.id)">
      <fa-icon icon="xmark"></fa-icon>
    </button>
  </div>
  <div #messageListContainer class="message-list-container main">
    <div id="me{{message.id}}" *ngFor="let message of messages" class="message-container flex">
      <div *ngIf="message.sender">
        <a routerLink="/profile/{{message.sender!.slug}}">
          <img class="avatar" [src]="message.sender!.photoImage! | image: 'avatar':'s1'" title="{{firstAndLastName(message.sender!)}}" alt="{{firstAndLastName(message.sender!)}}" />
        </a>
      </div>
      <div *ngIf="!message.sender">
        <img class="avatar" [src]="'' | image: 'avatar':'s1'" title="{{'profile.deleted' | translate}}" alt="{{'profile.deleted' | translate}}" />
      </div>
      <div class="message-panel flex-child dash">
        <div class="name-line">
          <a routerLink="/profile/{{message.sender!.slug}}" *ngIf="message.sender">{{firstAndLastName(message.sender!)}}</a>
          <span class="name-line" *ngIf="!message.sender">{{'profile.deleted' | translate}}</span>
          <span style="color: grey"> - {{message.sendedAt | localizedDate: 'DATETIME_SHORT' }}</span>
        </div>
        <div>
          <div class="message-body" [innerHTML]="message.textMsg" *ngIf="message.textMsg"></div>
          <div xa-media-player [src]="message.audioMsg" *ngIf="message.audioMsg"></div>
          <div *ngFor="let file of message.files" class="message-container flex">
            <img [src]="file.url" *ngIf="file.fileType=='image/jpeg'"/>
          <div>
        </div>
      </div>
    </div>
  </div>
  <div style="min-height: 50px" class="footer">
    <div *ngFor="let attachment of attachments" class="attachment-container pl">
      <fa-icon [icon]="getIconForMimeType(attachment.type)"></fa-icon>
      <div class="name">
        <div>{{ attachment.name}}</div>
        <div class="size">{{ attachment.size | formatFileSize:false }}</div>
      </div>
      <button (click)="removeAttachment(attachment.id)" mat-icon-button class="action-button-rounded mr">
        <fa-icon icon="xmark" class="xmark"></fa-icon>
      </button>
    </div>
    <form [formGroup]="form" #ngForm="ngForm" class="footer-items" novalidate>
      <ng-template [ngIf]="!isMediaRecorderVisible">
        <button (click)="fileUpload.click()" class="emoji-picker" style="font-size: 1.4rem;">
          <fa-icon class="icon" icon="paperclip"></fa-icon>
        </button>
        <!-- input type="file" hidden #fileUpload (change)="onFileSelected($event)" accept="image/jpeg,image/png,image/gif, video/mp4" / -->
        <input type="file" hidden #fileUpload (change)="onFileSelected($event)" accept="image/*,.pdf,.doc,.docx,.csv,.zip,.rar,.ppt,.pptx,.pps,.ppsx,.odt,.rtf,.xls,.xlsx,.txt" />
        <emoji-mart class="emoji-mart" set="twitter" [showPreview]="false" [enableSearch]="false" (emojiSelect)="addEmoji($event)" *ngIf="showEmojiPicker"></emoji-mart>
        <button (click)="toggleEmojiPicker()" class="emoji-picker" style="font-size: 1.4rem;">
          <fa-icon class="icon" icon="face-smile"></fa-icon>
        </button>
        <div formControlName="textMsg" #textMsg contenteditable="true" (keyup)="hasCharsX($event)" class="contenteditable" attr.placeholder="{{'message.placeholder' | translate}}" *ngIf="isContenteditable"></div>
        <div formControlName="textMsg" contenteditable="false" class="contenteditable" attr.placeholder="{{'message.placeholder' | translate}}" *ngIf="!isContenteditable"></div>
        <!-- div class="is-submitted-wheel" *ngIf="isSubmitted"></div -->
          <button (click)="sendMessage()" class="action-button-rounded" [disabled]="isSubmitted" *ngIf="hasChars">
            <fa-icon icon="paper-plane" ></fa-icon>
          </button>
          <button (click)="activateAudioReorder()" class="action-button-rounded" [disabled]="isSubmitted" *ngIf="!hasChars">
            <fa-icon icon="microphone"></fa-icon>
          </button>
        </ng-template>
      <ng-template [ngIf]="isMediaRecorderVisible">
        <div xa-media-recorder (mediaBlobOut)="sendMessage($event)" ></div>
      </ng-template>
    </form>
  </div>
</div>

<ng-template #menu>
  <mat-menu #appMenu="matMenu">
    <ng-template matMenuContent>
      <a mat-menu-item (click)="report()">{{'messahe.report' | translate}}</a>
      <a mat-menu-item (click)="archive()">{{'message.archive' | translate}}</a>
      <a mat-menu-item (click)="delete()">{{'message.delete' | translate}}</a>
    </ng-template>
  </mat-menu>
  <!-- <button class="action-button-rounded" [matMenuTriggerFor]="appMenu">
    <fa-icon icon="ellipsis-h"></fa-icon>
  </button> -->
</ng-template>
