<video #media width=”0” height=”0″ playsinline preload="metadata">
  <source src="{{src}}" type="video/mp4">
  Your browser does not support the video tag.
</video>
<div #controls class="player-controls">
  <button #playBtn class="player-play-btn" role="button" aria-label="Play" data-playing="false">
    <fa-icon [icon]="'play'" *ngIf="paused"></fa-icon>
    <fa-icon [icon]="'pause'" *ngIf="!paused"></fa-icon>
  </button>
  <div class="player-timeline">
    <span #currentTime class="player-time player-time-current">0:00</span>
    <div #progressRange class="progress-container progress-range" id="progress-container">
      <div #progress class="progress progress-bar" id="progress"></div>
    </div>
    <span #duration class="player-time player-time-duration">0:00</span>
  </div>
  <div class="player-volume-container">
    <span #volumeIcon class="volumeIcon">
      <fa-icon [icon]="'volume-xmark'" *ngIf="!volumeHigh"></fa-icon>
      <fa-icon [icon]="'volume-high'" *ngIf="volumeHigh"></fa-icon>
    </span>
    <div class="range-volume">
      <span class="under-ranger"></span>
      <input #volume class="volume" type="range" min="0" [attr.max]="1" value="1" step="0.1" disabled><span class="change-range"></span>
    </div>
  </div>
</div>
