import { Component, Inject, EventEmitter, Output, OnInit, AfterViewInit, AfterContentChecked, ViewChild, ElementRef, ChangeDetectorRef} from '@angular/core';
import { CommonModule } from '@angular/common';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { RouterModule, ActivatedRoute } from '@angular/router';
import { HttpEventType, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { MaterialModule } from '@shared/material.module';
import { environment } from '@environments/environment';

@Component({
  selector: '[xa-media]',
  templateUrl: './media.component.html',
  styleUrls: ['./media.component.scss'],
  standalone: true,
  imports: [TranslateModule, FontAwesomeModule, MaterialModule, RouterModule, CommonModule]
})
export class MediaComponent implements OnInit, AfterViewInit, AfterContentChecked {
  @ViewChild('dummyClick') dummyClickRef: ElementRef;
  @Output() selectedMediaFiles = new EventEmitter();
  isSubmitted: boolean = false;
  selectedFiles: FileList;
  files: IFile[] = [];
  activeFileIndex = 0;
  lastIndexPosition!: number;
  mode: string = 'view';
  state: string = 'initial';

  constructor(
    private dialogRef: MatDialogRef<MediaComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {
      images: any
    },
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.cdr.detach();
  }

  ngAfterViewInit(): void {
    if (this.data.images) {
      this.mode = 'view';
      for (const image of this.data.images) {
        this.files.push({
          url: environment.cdnUrl.concat(image.url),
          type: 'image'
        });
      };
    }
    else {
      this.mode = 'upload';
      this.dummyClickRef.nativeElement.click();
    }
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  onSelectFiles(event: any): void {
    this.selectedFiles = event.target.files;
    this.files = [];
    if (this.selectedFiles && this.selectedFiles[0]) {
      const numberOfFiles = this.selectedFiles.length;
      for (let i = 0; i < numberOfFiles; i++) {
        let reader: FileReader = new FileReader();
        reader.onload = (e: any) => {
          this.files.push({
            url: e.target.result,
            name: this.selectedFiles[i].name,
            type: this.selectedFiles[i].type,
            size: this.selectedFiles[i].size
          });
          // If one or more images have been uploaded, there must be no video in the file stack
          if (this.files.findIndex((file: any) => String(file.type).startsWith('image')) != -1) {
            this.files = this.files.filter((file: any) => !String(file.type).startsWith('video'));
          }
        }
        reader.onerror = (e: any) => {
          this.state = 'error';
        }
        reader.onloadend = (e: any) => {
          this.state = 'initial';
        }
        reader.readAsDataURL(this.selectedFiles[i]);
      };
    }
  }

  done(): void {
    this.selectedMediaFiles.emit(this.files)
  }

  onNext() {
    this.lastIndexPosition = this.files.length - 1;
    if (this.activeFileIndex >= this.lastIndexPosition) {
      this.activeFileIndex = 0;
    } else {
      this.activeFileIndex += 1;
    }
  }

  onPrevious() {
    this.lastIndexPosition = this.files.length - 1;
    if (this.activeFileIndex == 0) {
      this.activeFileIndex = this.lastIndexPosition;
    } else {
      this.activeFileIndex -= 1;
    }
  }
}

export interface IFile {
  url: any;
  name?: string;
  type: string;
  size?: any;
}
