import { Pipe, PipeTransform } from '@angular/core';

import { environment } from '@environments/environment';

@Pipe({
  name: 'video',
  standalone: true
})
export class VideoPipe implements PipeTransform {
  transform(video: string): string {
    return environment.cdnUrl.concat(video);
  }
}
