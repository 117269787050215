<div #share>
  <div [matMenuTriggerFor]="appMenu">
  <fa-icon [icon]="['far', 'share-from-square']"></fa-icon>
  <span *ngIf="deviceInfo.screenSizes['screen-mid']">{{'action.share' | translate}}</span>
</div>
</div>
<mat-menu #appMenu="matMenu">
  <h5>{{'share.thisPost' | translate}}</h5>
  <ng-template matMenuContent>
    <a mat-menu-item (click)="inFeed()">{{'share.menu.inFeed' | translate}}</a>
    <a mat-menu-item (click)="inFeedWithComment()">{{'share.menu.inFeedWithComment' | translate}}</a>
    <!-- a mat-menu-item (click)="asMessage()">{{'share.menu.asMessage' | translate}}</a -->
  </ng-template>
</mat-menu>
