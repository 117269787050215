import { NgModule, Component, EventEmitter, Input, OnInit, Output, AfterViewInit, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';

import { Credential } from '@modules/account/models/credential.model';
import { AccountService } from '@modules/account/services/account.service';
import { ProfileService } from '@modules/profile/services/profile.service';
import { ImagePipe } from '@core/util/image.pipe';
import { ContenteditableValueAccessor } from '@shared/contenteditable/contenteditable-value-accessor';

@Component({
  selector: '[xa-comment-form]',
  templateUrl: './comment-form.component.html',
  styleUrls: ['./comment-form.component.scss'],
  standalone: true,
  imports: [ImagePipe, TranslateModule, RouterModule, CommonModule, ReactiveFormsModule, ContenteditableValueAccessor]
})
export class CommentFormComponent implements OnInit, AfterViewInit {
  @ViewChild('text') inputElement: ElementRef;
  @Input() submitLabel!: string;
  @Input() hasCancelButton: boolean = false;
  @Input() initialText: string = '';
  @Input() isEditing: boolean = false;

  @Output()
  handleSubmit = new EventEmitter<string>();

  @Output()
  handleCancel = new EventEmitter<void>();

  isSubmitted: boolean = false;
  form: UntypedFormGroup;
  credential: Credential;

  constructor(
    private accountService: AccountService,
    private formBuilder: UntypedFormBuilder,
    private renderer: Renderer2
  ) {
    this.credential = this.accountService.credentialValue;
  }

  ngOnInit(): void {
    this.form = this.formBuilder.group({
      text: [this.initialText, Validators.required],
    });
  }

  ngAfterViewInit(): void {
    this.renderer.listen(this.inputElement.nativeElement, 'paste', (event) => {
      event.preventDefault();
      const div = document.createElement('div');
      div.innerHTML = event.clipboardData.getData('text/plain');
      const pastedText = div.textContent || div.innerText || "";
      if (window.getSelection()) {
        let selection = window.getSelection();
        let range = selection!.getRangeAt(0);
        let span = document.createElement('span');
        span.classList.add('tmp');
        span.innerHTML = pastedText;
        range.deleteContents();
        range.insertNode(span);
        //cursor at the last with this
        range.collapse(false);
        selection!.removeAllRanges();
        selection!.addRange(range);
      }
      let regex = /<\/?span[^>]*>/g
      this.inputElement.nativeElement.innerHTML = this.inputElement.nativeElement.innerHTML.replace(regex, "");
    });
  }

  onSubmit(): void {
    this.isSubmitted = true;
    this.handleSubmit.emit(this.form.value.text);
    this.form.reset();
    this.isSubmitted = false;
  }
}
