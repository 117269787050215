import { Injectable } from '@angular/core';
import { HttpParams, HttpClient, HttpResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { isPresent } from '@core/util/operators';
import { Profile } from '@modules/profile/models/profile.model';
import { ApiService } from '@core/services/api.service';
import { ObjectOrder } from '@modules/objectOrder.model';

@Injectable({
  providedIn: 'root'
})
export class ProfileService {
  public profiles: Profile[] = [];

  constructor(
    private apiService: ApiService,
    private http: HttpClient
  ) {}

  getProfiles(params: HttpParams): Observable<any> {
    const url = `/profiles/`;
    return this.apiService.get(url, params);
  }

  /* getProfile(id: number, params: HttpParams): Observable<any> {
    const url = `/profiles/id/${id}`;
    return this.apiService.get(url, params).pipe(map(data => data));
  }*/

  getProfile(id: number, anonymous: boolean, params: HttpParams): Observable<any> {
    const url = anonymous == true ? `/profiles/a/${id}` : `/profiles/${id}`;
    return this.apiService.get(url, params).pipe(map(data => data));
  }

  saveProfile(profile: Profile): Observable<any> {
    return this.apiService.post('/profiles/save', profile);
  }

  getContactInfo(slug: string, params: HttpParams): Observable<any> {
    const url = `/profiles/${slug}/contactinfo`;
    return this.apiService.get(url, params).pipe(map(data => data));
  }

  getExperiences(id: number, params: HttpParams): Observable<any> {
    const url = `/profiles/${id}/experiences`;
    return this.apiService.get(url, params);
  }

  saveExperience(experience: any): Observable<any> {
    return this.apiService.post('/profiles/experience/save', experience);
  }

  deleteExperience(params : HttpParams): Observable<any> {
    return this.apiService.delete('/profiles/experience/delete/', params);
  }

  saveExperienceOrder(experienceOrder: ObjectOrder): Observable<{}> {
    return this.apiService.post('/profiles/experience/saveOrder', experienceOrder);
  }

  getEducations(id: number, params: HttpParams): Observable<any> {
    const url = `/profiles/${id}/educations`;
    return this.apiService.get(url, params);
  }

  saveEducation(education: any): Observable<any> {
    return this.apiService.post('/profiles/education/save', education);
  }

  deleteEducation(params : HttpParams): Observable<any> {
    return this.apiService.delete('/profiles/education/delete/', params);
  }

  saveEducationOrder(educationOrder: ObjectOrder): Observable<{}> {
    return this.apiService.post('/profiles/education/saveOrder', educationOrder);
  }

  getResponsibilities(slug: string, params: HttpParams): Observable<any> {
    const url = `/profiles/${slug}/responsibilities`;
    return this.apiService.get(url, params);
  }

  saveAddress(address: any): Observable<any> {
    return this.apiService.post('/profiles/address/save', address);
  }

  deleteAddress(params : HttpParams): Observable<any> {
    return this.apiService.delete('/profiles/address/delete/', params);
  }

  savePhone(phone: any): Observable<any> {
    return this.apiService.post('/profiles/phone/save', phone);
  }

  deletePhone(params : HttpParams): Observable<any> {
    return this.apiService.delete('/profiles/phone/delete/', params);
  }

  saveMessenger(messenger: any): Observable<any> {
    return this.apiService.post('/profiles/messenger/save', messenger);
  }

  deleteMessenger(params : HttpParams): Observable<any> {
    return this.apiService.delete('/profiles/messenger/delete/', params);
  }

  saveWebsite(website: any): Observable<any> {
    return this.apiService.post('/profiles/website/save', website);
  }

  deleteWebsite(params : HttpParams): Observable<any> {
    return this.apiService.delete('/profiles/website/delete/', params);
  }

  lookupCountry(params: HttpParams): Observable<any> {
    return this.apiService.get('/geo/countries', params);
  }

  lookupPostalCode(params: HttpParams): Observable<any> {
    return this.apiService.get('/geo/postalcode', params);
  }
}
