import { Component, OnInit, AfterViewInit, Inject, RendererFactory2, ElementRef, Renderer2, ViewChild } from '@angular/core';
import { Title } from '@angular/platform-browser';
import { Router, ActivatedRouteSnapshot, NavigationEnd, NavigationError } from '@angular/router';
import { TranslateService, LangChangeEvent } from '@ngx-translate/core';
import { DOCUMENT } from '@angular/common';
// import { Observable } from 'rxjs';
// import { animate, state, style, transition, trigger } from '@angular/animations';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { SwUpdate, VersionReadyEvent } from '@angular/service-worker';
import { Observable, forkJoin, fromEvent } from "rxjs";
import { distinctUntilChanged, filter, map, pairwise, share, throttleTime } from 'rxjs/operators';
import { HttpParams } from '@angular/common/http';
import { SocialLoginModule, SocialAuthService } from '@abacritt/angularx-social-login';

import { Logger } from '@shared/logger/logger.service';
import { ColorSchemeService } from '@core/services/color-scheme.service';
import { AccountService } from '@modules/account/services/account.service';
import { DeviceInfoService } from '@core/services/device-info.service';
import { Account } from '@modules/account/models/account.model';
import { MessageService } from '@modules/message/services/message.service';
import { ContactService } from '@modules/contact/services/contact.service';
import { ThreadComponent } from '@modules/message/components/thread.component';
import { ThreadModalComponent } from '@modules/message/components/thread-modal.component';

@Component({
  selector: '[xa-main]',
  styleUrls: ['./main.component.scss'],
  templateUrl: './main.component.html',
})
export class MainComponent implements OnInit, AfterViewInit {
  @ViewChild("headerContainer") headerContainer: ElementRef;
  @ViewChild("footerContainer") footerContainer: ElementRef;
  unreadedMessages: any;
  contactRequests: any;
  numNotifications: number = 0;
  credential: any;
  lastScrollTop = 0;
  delta = 5;
  headerHeight = 60;

  constructor(
    private titleService: Title,
    private router: Router,
    private translate: TranslateService,
    private cookieService: SsrCookieService,
    private colorSchemeService: ColorSchemeService,
    private rootRenderer: RendererFactory2,
    private renderer: Renderer2,
    @Inject(DOCUMENT) private document: Document,
    public swUpdate: SwUpdate,
    public deviceInfo: DeviceInfoService,
    private accountService: AccountService,
    private messageService: MessageService,
    private contactService: ContactService,
    private logger: Logger,
    private socialAuthService: SocialAuthService,
        private threadComponent: ThreadComponent,
        private threadModalComponent: ThreadModalComponent

  ) {
    // this.renderer = this.rootRenderer.createRenderer(document.querySelector('html'), null);
    // this.renderer.setAttribute(document.querySelector('html'), 'lang', 'tr');
    // document.querySelector('html')?.setAttribute('lang', 'en');
    document.documentElement.setAttribute("lang", 'de');
  }

  ngOnInit(): void {
    this.accountService.credential.subscribe(credential => {
      this.credential = this.accountService.credentialValue;
    });

    // subscribe for app updates available
    this.swUpdate.available.subscribe(async () => {
      await this.swUpdate.activateUpdate()
      document.location.reload()
    })

    // try to log in automatically
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.renderer.removeClass(this.headerContainer.nativeElement, 'up');
        this.renderer.removeClass(this.footerContainer.nativeElement, 'up');
        this.updateTitle();

        if (this.credential && this.credential.profileId) {
          forkJoin([this.messageService.getUnreadedMessages(new HttpParams().set('my', this.credential.profileId)),
              this.contactService.getContactRequests(new HttpParams().set('my', this.credential.profileId).set('filter', 'received'))
            ])
            .subscribe(([respMessages, respContacts]) => {
                this.unreadedMessages = respMessages.data.unreadedMsg;
                this.contactRequests = respContacts.data.received;
              },
              error => {
                this.unreadedMessages = [];
                this.contactRequests = [];
                this.logger.log(error);
              }
            );
        }

      }
      if (event instanceof NavigationError && event.error.status == 404) {
        // this.router.navigate(['/404']);
      }
    });

    this.messageService.currentThread.subscribe((thread: any) => {
      this.unreadedMessages = this.unreadedMessages ? this.unreadedMessages : [];
      const itemIndex = this.unreadedMessages.findIndex((item: any) => item == thread);
      this.unreadedMessages.splice(itemIndex,1);
    });

    this.contactService.currentContact.subscribe((contact: any) => {
      this.contactRequests = this.contactRequests ? this.contactRequests : [];
      const itemIndex = this.contactRequests.findIndex((item: any) => item.id==contact.id);
      this.contactRequests.splice(itemIndex,1);
    });

    // language change
    this.translate.onLangChange.subscribe((langChangeEvent: LangChangeEvent) => {
      this.updateTitle();
      this.cookieService.set('locale', langChangeEvent.lang, 999, '/');
      this.renderer.setAttribute(document.querySelector('html'), 'lang', this.cookieService.get('locale'));
    });
    this.renderer.setAttribute(document.querySelector('html'), 'lang', this.cookieService.get('locale'));
    if (!this.cookieService.get('theme')) {
      this.cookieService.set('theme', 'light-theme', 999, '/');
    } else {
      this.colorSchemeService.update(this.cookieService.get('theme'));
    }

    // registration / login via social plugin
    this.socialAuthService.authState.subscribe((socialUser) => {
      let socialAuthObj: any = {};
      socialAuthObj.login = socialUser.email;
      socialAuthObj.password = null;
      socialAuthObj.langKey = this.translate.currentLang;
      let url = this.router.url.split("/");
      socialAuthObj.process = url[1].length > 0 ? url[1].split("?")[0] : 'register';
      if (Object.keys(socialAuthObj).length !== 0) {
        this.accountService.socialAuth(socialAuthObj).subscribe(
          (resp: any) => {
            let account = resp.account;

            // Social login provider => Registration completed
            if (account.profile) {
              this.accountService.authenticate(account).subscribe(() => {
                this.router.routeReuseStrategy.shouldReuseRoute = () => false;
                this.router.navigate(['/']);
              })
            }

            // Social login provider => Registration not finished (profile missing)
            else {
              this.router.navigate(['/activate'], {
                queryParams: {
                  accountId: account.id,
                  accessToken: account.accessToken
                }
              });
            }
          },
          (error: any) => {
            // error 001 => Account not found
            if (socialAuthObj.process == 'login') {
              this.router.navigate(['/login'], {
                state: {
                  error: '001'
                }
              });
            }
            // error 005 => Email address already registered
            if (socialAuthObj.process == 'register') {
              this.router.navigate(['/register'], {
                state: {
                  error: '005'
                }
              });
            }
          });
      }
    });
  }

  ngAfterViewInit() {
    const scroll$ = fromEvent(window, 'scroll').pipe(
      throttleTime(10),
      map(() => window.pageYOffset),
      pairwise(),
      distinctUntilChanged(),
      share()
    );
    scroll$.subscribe(() => {
      if (!this.deviceInfo.screenSizes['screen-wide']) {
        var st = window.pageYOffset;

        // Make sure they scroll more than delta
        if (Math.abs(this.lastScrollTop - st) <= this.delta)
          return;

        if (st > this.lastScrollTop && st > this.headerHeight) {
          // Scroll Down
          this.renderer.addClass(this.headerContainer.nativeElement, 'up');
          this.renderer.addClass(this.footerContainer.nativeElement, 'up');
        } else {
          // Scroll Up
          if (st + window.innerHeight < document.body.clientHeight) {
             this.renderer.removeClass(this.headerContainer.nativeElement, 'up');
             this.renderer.removeClass(this.footerContainer.nativeElement, 'up');
          }
        }
        this.lastScrollTop = st;
      }
    });
  }

  private updateTitle(): void {
    let pageTitle = this.getPageTitle(this.router.routerState.snapshot.root);
    if (!pageTitle) {
      pageTitle = 'global.title.application';
    }
    this.translate.get(pageTitle).subscribe(title => this.titleService.setTitle(title));
  }

  private getPageTitle(routeSnapshot: ActivatedRouteSnapshot): string {
    // this.router.getCurrentNavigation().extras.state.pageTitle! ??
    let title: string = routeSnapshot.data['pageTitle'] ?? '';
    if (routeSnapshot.firstChild) {
      title = this.getPageTitle(routeSnapshot.firstChild) || title;
    }
    return title;
  }
}
