import { Inject, RendererFactory2, Renderer2, NgModule } from '@angular/core';
import { DatePipe, registerLocaleData, DOCUMENT } from '@angular/common';
import { BrowserModule, TransferState } from '@angular/platform-browser';
import { TitleStrategy } from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClient, HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { TranslateModule, TranslateService, TranslateLoader, MissingTranslationHandler } from '@ngx-translate/core';
import { FaIconLibrary } from '@fortawesome/angular-fontawesome';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { NgxStripeModule } from 'ngx-stripe';
import { SocialLoginModule, SocialAuthServiceConfig, GoogleLoginProvider, FacebookLoginProvider } from '@abacritt/angularx-social-login';
import { SharedModule} from '@shared/shared.module';
import { httpInterceptorProviders } from '@core/interceptor/index';
import { translateBrowserLoaderFactory, missingTranslationHandler } from '@core/loader/translate-browser.loader';
import { fontAwesomeIcons } from '@app/config/font-awesome-icons';
import { AppRoutingModule } from '@app/app-routing.module';
import { LoginComponent } from '@modules/login/components/login.component';
import { LogoutComponent } from '@modules/logout/components/logout.component';
import { MainComponent } from '@app/layouts/main/main.component';
import { HeaderComponent } from '@app/layouts/header/header.component';
import { FooterComponent } from '@app/layouts/footer/footer.component';
import { MessageSwitchComponent } from '@modules/message/components/message-switch.component';
import { ActiveMenuDirective } from '@app/layouts/header/active-menu.directive';
import { ReportComponent } from '@modules/report/components/report.component';
import { ContactRequestComponent } from '@modules/contact/components/contact-request.component';
import { CustomPageTitleStrategy } from '@core/util/custom-page-title-strategy';
import { MetadataService } from '@core/services/metadata.service';
import { ThreadComponent } from '@modules/message/components/thread.component';
import { ThreadModalComponent } from '@modules/message/components/thread-modal.component';

import '@angular/common/locales/global/en';
import '@angular/common/locales/global/de';
import '@angular/common/locales/global/es';

import { ServiceWorkerModule } from '@angular/service-worker';
import { environment } from '../environments/environment';

const googleClientId = environment.googleClientId;

@NgModule({
  declarations: [
    MainComponent,
    HeaderComponent,
    FooterComponent,
    LoginComponent,
    LogoutComponent,
    ActiveMenuDirective,
    ContactRequestComponent,
    ReportComponent
  ],
  imports: [
    BrowserModule.withServerTransition({
      appId: 'xaneda'
    }),
    FormsModule,
    HttpClientModule,
    SocialLoginModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        // useFactory: translatePartialLoader,
        useFactory: translateBrowserLoaderFactory,
        deps: [HttpClient, TransferState],
      },
      missingTranslationHandler: {
        provide: MissingTranslationHandler,
        useFactory: missingTranslationHandler,
      },
    }),
    BrowserAnimationsModule,
    SharedModule,
    AppRoutingModule,
    NgxStripeModule.forChild(),
    ServiceWorkerModule.register('ngsw-worker.js'),
    MessageSwitchComponent
  ],
  exports: [
    // MaterialModule
  ],
  providers: [
    httpInterceptorProviders,
    ThreadComponent,
    ThreadModalComponent,
    MetadataService,
    DatePipe,
    {
      provide: 'SocialAuthServiceConfig',
      useValue: {
        autoLogin: false,
        providers: [{
            id: GoogleLoginProvider.PROVIDER_ID,
            provider: new GoogleLoginProvider(googleClientId)
          },
          {
            id: FacebookLoginProvider.PROVIDER_ID,
            provider: new FacebookLoginProvider('clientId')
          }
        ],
        onError: (err:any) => {
          console.error(err);
        }
      } as SocialAuthServiceConfig,
    },
  ],
  bootstrap: [MainComponent]
})
export class AppModule {
  constructor(
    cookieService: SsrCookieService,
    translate: TranslateService,
    iconLibrary: FaIconLibrary,
  ) {
    let locale = cookieService.get('locale');
    if (!locale) {
      if (navigator.language && navigator.language.substring(0,2) !== 'de') {
        translate.setDefaultLang('en');
        translate.use('en');
        cookieService.set('locale', 'en', 999, '/');
      }
      else {
        translate.setDefaultLang('de');
        translate.use('de');
        cookieService.set('locale', 'de', 999, '/');
      }
    } else {
      translate.setDefaultLang(locale);
      translate.use(locale);
    }
    iconLibrary.addIcons(...fontAwesomeIcons);
  }
}
