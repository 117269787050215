import { Component, OnInit, EventEmitter, Input, Output } from "@angular/core";
import { CommonModule } from '@angular/common';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';

import { ImagePipe } from '@core/util/image.pipe';
import { Item } from "./item.model";

interface PendingSelection {
  [key: number]: boolean;
}

@Component({
  selector: "[xa-dual-select]",
  styleUrls: ["./dual-select.component.scss"],
  templateUrl: "./dual-select.component.html",
  standalone: true,
  imports: [ImagePipe, ReactiveFormsModule, TranslateModule, CommonModule]
})
export class DualSelectComponent implements OnInit {
  @Input() items: Item[];
  @Output() fromDualSelectComp = new EventEmitter<{obj: any}>();
  public pendingSelection: PendingSelection;
  public selectedItems: Item[];
  public unselectedItems: Item[];

  constructor() {}

  ngOnInit(): void {
    // To start with, all of the items will be unselected. Then, the user will be
    // able to move any of the items over to the selected collection.
    this.unselectedItems = this.items.slice().sort(this.sortItemOperator);
    this.selectedItems = [];

    // I am an ID-based look-up index that keeps track of which items have been
    // selected for pending changes (either adding or removing from the selected
    // items collection).
    this.pendingSelection = Object.create(null);
  }

  ngOnChanges(changes: any){
    this.unselectedItems = this.items.slice().sort(this.sortItemOperator);
    if (this.selectedItems) {
      this.selectedItems.forEach(selItem => {
        const i = this.unselectedItems.findIndex((unselItem:Item) => unselItem.id == selItem.id);
        if(i > -1) {
          this.unselectedItems.splice(i, 1)
        }
      })
    }
  }

  public addToSelectedItems(item?: Item): void {
    var changeItems = (item)
      // If a given item has been provided (via double-click), that's the single
      // item that we want to move.
      ?
      [item]
      // Otherwise, default to using the pending-selection index as the source of
      // items to move.
      :
      this.getPendingSelectionFromCollection(this.unselectedItems);

    // Now that we know which items we want to move, reset the pending-selection.
    this.pendingSelection = Object.create(null);

    // Remove each pending item from the unselected list.
    this.unselectedItems = this.removeItemsFromCollection(this.unselectedItems, changeItems);

    // We always want to move the pending items onto the front / top of the
    // selected list so that the change is VISUALLY OBVIOUS to the user.
    this.selectedItems = changeItems.concat(this.selectedItems);
    let obj:any = {
      selectedItems: this.selectedItems
    }
    this.fromDualSelectComp.emit(obj);
  }

  public removeFromSelectedItems(item ? : Item): void {
    var changeItems = (item) ?  [item] : this.getPendingSelectionFromCollection(this.selectedItems);
    this.pendingSelection = Object.create(null);
    this.selectedItems = this.removeItemsFromCollection(this.selectedItems, changeItems);
    let obj:any = {
      selectedItems: this.selectedItems
    }
    this.fromDualSelectComp.emit(obj);

    this.unselectedItems = changeItems
      .concat(this.unselectedItems)
      .sort(this.sortItemOperator);
  }

  public removeAllFromSelectedItems(): void {
    // var changeItems = (item) ?  [item] : this.getPendingSelectionFromCollection(this.selectedItems);
    this.pendingSelection = Object.create(null);
    // this.selectedItems = this.removeItemsFromCollection(this.selectedItems, changeItems);

    this.unselectedItems = this.unselectedItems
      .concat(this.selectedItems)
      .sort(this.sortItemOperator);
    this.selectedItems = [];
    let obj:any = {
      selectedItems: []
    }
    this.fromDualSelectComp.emit(obj);

  }

  private getPendingSelectionFromCollection(collection: Item[]): Item[] {
    var selectionFromCollection = collection.filter(
      (item) => {
        return (item.id in this.pendingSelection);
      }
    );
    return (selectionFromCollection);
  }

  private removeItemsFromCollection(collection: Item[], itemsToRemove: Item[]): Item[] {
    var collectionWithoutItems = collection.filter((item) => {
        return (!itemsToRemove.includes(item));
      }
    );
    return (collectionWithoutItems);
  }

  private sortItemOperator(a: Item, b: Item): number {
    return (a.name.localeCompare(b.name));
  }
}
