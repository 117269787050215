<div *ngIf="origin!='url' && post">
  <ng-container *ngTemplateOutlet="postTempl; context: {post: post}"></ng-container>
</div>

<div class="flex-layout" style="position:relative" *ngIf="origin=='url' && post">
  <div class="main-container">
    <div class="panel-container mb-20">
      <ng-container *ngTemplateOutlet="postTempl; context: {post: post}"></ng-container>
    </div>
  </div>
  <div class="panel-container" *ngIf="!credential">
    <div class="discover">
      <div>{{'post.discover.register' | translate: {groupName: post.name} }}
        <a class="nav-link" routerLink="/register">{{'global.discover.link.register' | translate}}</a>
      </div>
      <div>{{'global.discover.alreadyMember' | translate}} <a class="nav-link" routerLink="/login">{{'global.discover.link.login' | translate}}</a></div>
    </div>
  </div>
  <div class="side-container" *ngIf="deviceInfo.screenSizes['screen-mid']">
    <div class="panel-container mb-20">
      <div *ngIf="post.group">
        <a routerLink="/groups/{{post.group.slug}}">
          <div style="display:flex">
            <div class="logo">
              <img [src]="post.group.logoImage | image: 'group':'s1'" alt="logo" />
            </div>
            <h5>{{post.group.name}}</h5>
          </div>
          <div [innerHTML]="post.group.intro"></div>
        </a>
      </div>
      <div *ngIf="post.organization">
        <a routerLink="/organizations/{{post.organization.slug}}">
          <div style="display:flex">
            <div class="logo">
              <img [src]="post.organization.logoImage | image: 'organization':'s1'" alt="logo" />
            </div>
            <h5>{{post.organization.name}}</h5>
          </div>
          <div [innerHTML]="post.organization.slogan"></div>
        </a>
      </div>
    </div>
  </div>
</div>

<ng-template class="post" #postTempl let-post="post">
  <div class="post-reaction" *ngIf="post.reaction.likes && post.reaction.likes.length > 0">
    <a routerLink="/profile/{{post.reaction.likes[0].slug}}">
      <img class="avatar" [src]="post.reaction.likes[0].photoImage | image: 'avatar':'s1'" title="{{firstAndLastName(post.reaction.likes[0])}}" alt="{{firstAndLastName(post.reaction.likes[0])}}" />
    </a>
    <div>
      <a routerLink="/profile/{{post.reaction.likes[0].slug}}">
        <span class="first-last-name">{{firstAndLastName(post.reaction.likes[0])}}</span>
      </a>
      <ng-template [ngIf]="post.reaction.likes.length == 1">
        {{'like.post.1' | translate}}
      </ng-template>
      <ng-template [ngIf]="post.reaction.likes.length == 2">
        {{'like.post.2' | translate}}
      </ng-template>
      <ng-template [ngIf]="post.reaction.likes.length > 2">
        {{'like.post.3' | translate}}
      </ng-template>
    </div>
  </div>

  <div class="post-reaction" *ngIf="post.reaction.shares && post.reaction.shares.length > 0">
    <a routerLink="/profile/{{post.reaction.shares[0].slug}}">
      <img class="avatar" [src]="post.reaction.shares[0].photoImage | image: 'avatar':'s1'" title="{{firstAndLastName(post.reaction.shares[0])}}" alt="{{firstAndLastName(post.reaction.shares[0])}}" />
    </a>
    <div>
      <a routerLink="/profile/{{post.reaction.shares[0].slug}}">
        <span class="first-last-name">{{firstAndLastName(post.reaction.shares[0])}}</span>
      </a>
      <ng-template [ngIf]="post.reaction.shares.length == 1">
        {{'share.post.1' | translate}}
      </ng-template>
      <ng-template [ngIf]="post.reaction.shares.length == 2">
        {{'share.post.2' | translate}}
      </ng-template>
      <ng-template [ngIf]="post.reaction.shares.length > 2">
        {{'share.post.3' | translate}}
      </ng-template>
    </div>
  </div>

  <div class="post-header">
    <div>
      <img [src]="post.author.photoImage | image: 'avatar':'s1'" class="avatar" title="{{post.author.firstName}} {{post.author.lastName}}" alt="{{post.author.firstName}} {{post.author.lastName}}" />
    </div>
    <div class="group-name-menu">
      <div class="name">
        {{post.author.firstName}} {{post.author.lastName}}
      </div>
      <div class="info-label" *ngIf="post.author.slogan">{{post.author.slogan}}</div>
      <div class="info-label">{{post.postedAt | localizedDate: 'DATETIME_SHORT' }}
        <span *ngIf="post.visibility=='a'">
          <fa-icon icon="globe" matTooltip="{{'post.visibility.a' | translate}}" matTooltipPosition="above"></fa-icon>
        </span>
        <span *ngIf="post.visibility=='c'">
          <fa-icon icon="people-arrows" matTooltip="{{'post.visibility.c' | translate}}" matTooltipPosition="above"></fa-icon>
        </span>
        <span *ngIf="origin=='feed' && post.group">
          {{'feed.group' | translate: {group: post.group.name} }}
        </span>
        <span *ngIf="origin=='feed' && post.organization">
          {{'feed.organization' | translate: {organization: post.organization.name} }}
        </span>
      </div>
    </div>
    <div class="mat-menu">
      <mat-menu #appMenu="matMenu">
        <ng-template matMenuContent *ngIf="isEditable(post)">
          <a mat-menu-item (click)="editPost(post)">{{'post.edit' | translate}}</a>
          <a mat-menu-item (click)="deletePost(post)">{{'post.delete' | translate}}</a>
        </ng-template>
        <ng-template matMenuContent *ngIf="!isEditable(post)">
          <a mat-menu-item (click)="reportPost(post)">{{'post.report' | translate}}</a>
        </ng-template>
        <a mat-menu-item (click)="copyPostLink(post)">{{'post.copyLink' | translate}}</a>
      </mat-menu>
      <button class="action-button-rounded" [matMenuTriggerFor]="appMenu">
        <fa-icon icon="ellipsis-h"></fa-icon>
      </button>
    </div>
  </div>

  <ng-template [ngIf]="origin=='feed'">
    <div class="bold" [innerHTML]="post.subject" *ngIf="post.subject"></div>
  </ng-template>

  <ng-template [ngIf]="origin=='group' || (post.groupId && origin=='url')">
    <h3 class="flex-row">{{post.subject}}</h3>
    <div class="group-name flex-row" *ngIf="post.groupId && origin=='url'">
      {{ post.group.name }}
    </div>
    <div class="forum-name flex-row">
      {{'post.forum.label' | translate: {name: post.forum.name} }} - {{post.postedAt | localizedDate: 'DATETIME_SHORT' }}
      <span class="mod-post" *ngIf="post.modPost===true">{{'post.modPost' | translate}}</span>
    </div>
  </ng-template>

  <ng-template [ngIf]="origin=='feed' || origin=='group'">
    <div class="post" xa-read-more [text]="post.textEnriched"></div>
  </ng-template>

  <ng-template [ngIf]="origin!='feed' && origin!='group'">
    <div class="post" [innerHTML]="post.textEnriched"></div>
  </ng-template>
  <div style="position: relative" *ngIf="post.files.length > 0 && post.files[0].fileType==='image/jpeg'">
    <div class="image-grid image-grid-h images-{{post.files.length > 5 ? 5 : post.files.length}}" (click)="viewImages(post.files)">
      <div *ngFor="let file of post.files.slice(0, 5)">
        <img [src]="file.url | image: 'post'">
      </div>
    </div>
    <div class="image-plus" *ngIf="post.files && post.files.length > 5">
      + {{post.files.length - 5}}
    </div>
  </div>
  <div style="position: relative" *ngIf="post.files.length == 0 && post.status=='imageLoading'">
    Loading images
  </div>
  <div style="position: relative" *ngIf="post.files.length > 0 && post.files[0].fileType==='video/mp4'">
    <video controls style="width:100%" playsinline preload="metadata" loop="loop" [muted]="true" [src]="post.files[0].url | video" type="video/mp4">
    </video>
  </div>
  <div style="position: relative" *ngIf="post.files.length == 0 && post.status=='videoLoading'">
    Loading video
  </div>
  <div style="height: 600px" *ngIf="post.files[0] && post.files[0].fileType=='application/pdf'">
    <ng2-pdfjs-viewer [pdfSrc]="post.files[0].url | document"></ng2-pdfjs-viewer>
  </div>
  <div style="position: relative" *ngIf="post.files.length == 0 && post.status=='documentLoading'">
    Loading document
  </div>

  <div xa-preview-link [metadata]="post.metadata" *ngIf="post.metadata && post.files.length == 0"></div>

  <div *ngIf="credential">
    <div class="statistic" *ngIf="post.reaction && (post.reaction.numLikes > 0 || post.reaction.numShares > 0 || post.reaction.numComments > 0)">
      <div *ngIf="post.reaction.numLikes > 0">
        <a [routerLink]="" (click)="listLikes(post.id, 'post')" style="margin-right: auto">
          <fa-icon [icon]="['far', 'thumbs-up']"></fa-icon>
          <span style="margin-left: 5px">{{post.reaction.numLikes}}</span>
        </a>
      </div>
      <div class="right-panel">
        <div *ngIf="post.reaction.numComments > 0">
          <a [routerLink]="" (click)="setComment(post.id)" style="margin: 0 auto">
            <span *ngIf="post.reaction.numComments == 1">
              {{'comment.numComment' | translate: {num: post.reaction.numComments} }}
            </span>
            <span *ngIf="post.reaction.numComments > 1">
              {{'comment.numComments' | translate: {num: post.reaction.numComments} }}
            </span>
          </a>
        </div>
        <div *ngIf="post.reaction.numShares > 0">
          <a [routerLink]="" (click)="listShares(post.id)" style="margin-left: 10px;">
            <span>{{'share.multTimes' | translate: {num: post.reaction.numShares} }}</span>
          </a>
        </div>
      </div>
    </div>
    <div class="action-icons" *ngIf="post.reaction">
      <div xa-like-icon (isLikedChange)="isLikedChange($event, post)" [likedId]="post.id" [likedType]="'post'" [isLiked]="post.reaction.isLiked"></div>
      <div xa-comment-icon [objectId]="post.id" (expansionEvent)="setComment(post.id)"></div>
      <div xa-share-icon (isSharedChange)="isSharedChange($event, post)" [sharedId]="post.id" [sharedType]="'post'"></div>
    </div>
    <div xa-list-comments [origin]="'post'" [id]="post.id" [expansionId]="expansionId" *ngIf="post.id==expansionId"></div>
  </div>
</ng-template>
