import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';

import { Contact } from '@modules/contact/models/contact.model';
import { ApiService } from '@core/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class ContactService {
  private contactSource = new BehaviorSubject<any>('');
  public currentContact = this.contactSource.asObservable();

  constructor(
    private apiService: ApiService
  ) {}

  getContacts(params : HttpParams): Observable<any> {
    return this.apiService.get('/contacts', params);
  }

  getContactRequests(params : HttpParams): Observable<any> {
    return this.apiService.get('/contacts/requests', params);
  }

  getNumContactRequests(params : HttpParams): Observable<any> {
    return this.apiService.get('/contacts/numrequests', params);
  }

  request(request: any): Observable<{}> {
    return this.apiService.post('/contacts/request', request);
  }

  getContactRequestById(params : HttpParams): Observable<any> {
    return this.apiService.get('/contacts/requests', params);
  }

  getContactSuggestions(params : HttpParams): Observable<any> {
    return this.apiService.get('/contacts/suggestions', params);
  }

  setCurrentContactId(contactId: any): void {
    this.contactSource.next(contactId);
  }
}
