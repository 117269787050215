import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ApiService } from '@core/services/api.service';

@Injectable({
  providedIn: 'root'
})

export class SearchService {
  constructor(
    private apiService: ApiService
  ) { }

	search(params : HttpParams): Observable<any> {
    return this.apiService.get('/search', params);
  }

	searchProfile(params : HttpParams): Observable<any> {
    return this.apiService.get('/search/profile', params);
  }
}
