import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
// import { BehaviorSubject, Observable, of } from 'rxjs';
import { HttpParams } from '@angular/common/http';

import { Comment } from '@modules/comment/models/comment.model';
import { ApiService } from '@core/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class CommentService {


  buttonClickSubject: Subject<any> = new BehaviorSubject(-1);
  buttonClick$: Observable<any> = this.buttonClickSubject.asObservable();
    private subject: Subject<any>;
    private observable$: Observable<any>;

  constructor(
    private apiService: ApiService,
  ) {
    this.subject = new Subject();
    this.observable$ = this.subject.asObservable();
  }

  getComments(params : HttpParams): Observable<any> {
    return this.apiService.get('/comments', params);
  }

  saveComment(comment: Comment): Observable<{}> {
    return this.apiService.post('/comments/save', comment);
  }

  deleteComment(params : HttpParams): Observable<any> {
    return this.apiService.delete('/comments/delete/', params);
  }

  sendClick(data: any): void {
    this.subject.next(data);
  }

  clearClick(): void {
    this.subject.next(null);
  }

  getClick(): Observable<any> {
    return this.observable$;
  }
}
