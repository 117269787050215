<div mat-dialog-title class="dialog-title">
  <h2  *ngIf="mode=='upload'">{{'post.media.select.title' | translate}}</h2>
  <button mat-icon-button aria-label="close dialog" mat-dialog-close>
    <fa-icon icon="xmark" class="xmark"></fa-icon>
  </button>
</div>
<div mat-dialog-content>
  <div *ngIf="state=='loading'">{{'post.upload.processing' | translate}}</div>
  <div *ngIf="files.length!=0 && files[0].type!.startsWith('image')">
    <ng-container *ngTemplateOutlet="imageTempl; context: {files: files}"></ng-container>
  </div>
  <div *ngIf="files.length!=0 && files[0].type!.startsWith('video')">
    <ng-container *ngTemplateOutlet="videoTempl; context: {files: files}"></ng-container>
  </div>
  <div *ngIf="files.length==0 && state!='loading'">
    <a (click)="dummyClick.click()" style="color: #3f51b5;">{{'post.media.select.label' | translate}}</a>
  </div>
  <input type="file" multiple="multiple" hidden #dummyClick (change)="onSelectFiles($event)" accept="image/jpeg,image/png,image/gif, video/mp4" />
</div>
<div mat-dialog-actions align="end" *ngIf="mode=='upload'">
  <button mat-flat-button mat-dialog-close>
    {{'entity.action.cancel' | translate}}
  </button>
  <button type="submit" (click)="done()" mat-flat-button color="primary" [disabled]="isSubmitted">
    {{'entity.action.done' | translate}}
  </button>
</div>
<div mat-dialog-actions align="end" *ngIf="mode=='view'">
  <button mat-flat-button color="primary" mat-dialog-close>
    {{'entity.action.close' | translate}}
  </button>
</div>

<ng-template class="post" #imageTempl let-images="files">
  <div class="carousel-container">
    <a (click)="onPrevious()" class="carousel-btn previous">
      <fa-icon class="chevron" icon="arrow-left"></fa-icon>
    </a>
    <a (click)="onNext()" class="carousel-btn next">
      <fa-icon class="chevron" icon="chevron-right"></fa-icon>
    </a>
    <div *ngFor="let image of images; let i = index;" class="carousel-wrapper">
      <ng-container *ngIf="i == activeFileIndex">
        <img [src]="image.url" [alt]="image.alt">
      </ng-container>
    </div>
  </div>
</ng-template>

<ng-template class="post" #videoTempl let-videos="files">
  <div class="carousel-container">
    <video [src]=videos[0].url style="width:100%" controls></video>
  </div>
</ng-template>
