import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ApiService } from '@core/services/api.service';
import { Visit } from '@modules/visit/models/visit.model';

@Injectable({
  providedIn: 'root'
})

export class VisitService {
  constructor(
    private apiService: ApiService
  ) { }

  getVisits(params : HttpParams): Observable<any> {
    return this.apiService.get('/visits', params);
  }

  saveVisit(visit: Visit): Observable<{}> {
    return this.apiService.post('/visits', visit);
  }
}
