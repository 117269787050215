import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { FormControl, UntypedFormBuilder, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';

import { AccountService } from '@modules/account/services/account.service';
import { MetadataService } from '@core/services/metadata.service';

@Component({
  selector: 'xa-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {
  @ViewChild('login', { static: false })
  login!: ElementRef;
  reason: any;
  isSubmitted: boolean = false;
  authenticationError: boolean = false;
  isPasswordVisible: boolean = false;
  form = this.formBuilder.group({
    login: ['', [Validators.required, Validators.minLength(6)]],
    password: ['', [Validators.required, Validators.minLength(6)]]
  });

  constructor(
    private formBuilder: UntypedFormBuilder,
    private accountService: AccountService,
    private translate: TranslateService,
    private metadataService: MetadataService,
    private router: Router
  ) {}

  ngOnInit(): void {
    this.metadataService.updateMetadata({
      title: this.translate.instant('login.title'),
      description: this.translate.instant('login.description'),
      type: "website"
    });
    if (history && history.state && history.state.error) {
      this.authenticationError = true;
      this.reason = '001';
    }
  }

  onclicktoggle(): void {
    this.isPasswordVisible = !this.isPasswordVisible;
  }

  doLogin(): void {
    this.isSubmitted = true;
    this.accountService.login(this.form.getRawValue()).subscribe({
      next: () => {
        this.isSubmitted = false;
        this.authenticationError = false;
        if (!this.router.getCurrentNavigation()) {
        // There were no routing during login (eg from navigationToStoredUrl)
           this.router.navigate(['']);
        }
        // this.router.navigate(['']);
      },
      error: (resp:any) => {
        this.isSubmitted = false;
        this.authenticationError = true;
        this.reason = resp.error.reason;
      }
    })
  }
}
