<div mat-dialog-title class="dialog-title">
  <h2 *ngIf="likedType=='post'">{{'like.title.post' | translate}}</h2>
  <h2 *ngIf="likedType=='event'">{{'like.title.event' | translate}}</h2>
  <button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <fa-icon icon="xmark" class="xmark"></fa-icon>
  </button>
</div>
<div class="list" *ngIf="likes">
  <div class="list-item like-item" *ngFor="let like of likes">
    <a routerLink="/profile/{{like.likedBy.slug}}">
      <img class="avatar" [src]="like.likedBy.photoImage | image: 'avatar':'s1'" title="{{like.likedBy.firstName}} {{like.likedBy.lastName}}" alt="{{like.likedBy.firstName}} {{like.likedBy.lastName}}" />
    </a>
    <div class="flex-column">
      <a routerLink="/profile/{{like.likedBy.slug}}">
        <span matLine class="name-item">{{like.likedBy.firstName}} {{like.likedBy.lastName}}</span>
      </a>
      <span matLine *ngIf="like.likedBy.slogan">{{like.likedBy.slogan}}</span>
    </div>
  </div>
</div>
<div xa-spinner *ngIf="!likes"></div>
