import {
  faAngleDown,
  faAngleUp,
  faArrowLeft,
  faArrowRight,
  faArrowUpRightFromSquare,
  faArrowsV,
  faAsterisk,
  faBan,
  faBars,
  faBell,
  faBook,
  faBookmark,
  faBriefcase,
  faBullhorn,
  faCakeCandles,
  faCalendar,
  faCalendarAlt,
  faCamera,
  faChartLine,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleCheck,
  faCircleChevronDown,
  faCloud,
  faCogs,
  faComment as fasComment,
  faCopy,
  faDatabase,
  faEdit,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  faFaceSmile as fasFaceSmile,
  faFile,
  faFileCsv,
  faFileExcel,
  faFileImage,
  faFileLines,
  faFilePdf,
  faFilePowerpoint,
  faFileWord,
  faFilter,
  faFlag,
  faGear,
  faGlobe,
  faGrip,
  faHashtag,
  faHeart,
  faHome,
  faImage,
  faIndustry,
  faInfo,
  faInfoCircle,
  faInstitution,
  faList,
  faListCheck,
  faLock,
  faMapMarker,
  faMessage,
  faMicrophone,
  faNewspaper,
  faPaperclip,
  faPaperPlane,
  faPause,
  faPencilAlt,
  faPenToSquare,
  faPeopleArrows,
  faPhone,
  faPlay,
  faPlus,
  faQuestion,
  faRightFromBracket,
  faRoad,
  faSave,
  faSearch,
  faShare as fasShare,
  faSignOutAlt,
  faSignInAlt,
  faSort,
  faSortDown,
  faSortUp,
  faSync,
  faTachometerAlt,
  faTasks,
  faThList,
  faThumbsUp as fasThumbsUp,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUpRightFromSquare,
  faUser,
  faUserCircle,
  faUserGroup,
  faUserPlus,
  faUsers,
  faUsersCog,
  faVideo,
  faVolumeDown,
  faVolumeHigh,
  faVolumeUp,
  faVolumeXmark,
  faWrench,
  faXmark,
} from '@fortawesome/free-solid-svg-icons';

import {
  faComment as farComment,
  faFaceSmile as farFaceSmile,
  faShareFromSquare as farShareFromSquare,
  faThumbsUp as farThumbsUp,
} from '@fortawesome/free-regular-svg-icons';

export const fontAwesomeIcons = [
  faAngleDown,
  faAngleUp,
  faArrowLeft,
  faArrowRight,
  faArrowUpRightFromSquare,
  faArrowsV,
  faAsterisk,
  faBan,
  faBars,
  faBell,
  faBook,
  faBookmark,
  faBriefcase,
  faBullhorn,
  faCakeCandles,
  faCalendar,
  faCalendarAlt,
  faCamera,
  faChartLine,
  faCheck,
  faChevronDown,
  faChevronLeft,
  faChevronRight,
  faChevronUp,
  faCircle,
  faCircleCheck,
  faCircleChevronDown,
  faCloud,
  faCogs,
  farComment,
  fasComment,
  faCopy,
  faDatabase,
  faEdit,
  faEllipsisH,
  faEllipsisV,
  faEnvelope,
  faExclamationTriangle,
  faEye,
  faEyeSlash,
  fasFaceSmile,
  farFaceSmile,
  faFile,
  faFileCsv,
  faFileExcel,
  faFileImage,
  faFileLines,
  faFilePdf,
  faFilePowerpoint,
  faFileWord,
  faFilter,
  faFlag,
  faGear,
  faGlobe,
  faGrip,
  faHashtag,
  faHeart,
  faHome,
  faImage,
  faIndustry,
  faInfo,
  faInfoCircle,
  faInstitution,
  faList,
  faListCheck,
  faLock,
  faMapMarker,
  faMessage,
  faMicrophone,
  faNewspaper,
  faPaperclip,
  faPaperPlane,
  faPause,
  faPencilAlt,
  faPenToSquare,
  faPeopleArrows,
  faPhone,
  faPlay,
  faPlus,
  faQuestion,
  faRightFromBracket,
  faRoad,
  faSave,
  faSearch,
  fasShare,
  farShareFromSquare,
  faSignOutAlt,
  faSignInAlt,
  faSort,
  faSortDown,
  faSortUp,
  faSync,
  faTachometerAlt,
  faTasks,
  faThList,
  farThumbsUp,
  fasThumbsUp,
  faTimes,
  faTimesCircle,
  faTrashAlt,
  faUpRightFromSquare,
  faUser,
  faUserCircle,
  faUserGroup,
  faUserPlus,
  faUsers,
  faUsersCog,
  faVideo,
  faVolumeDown,
  faVolumeHigh,
  faVolumeUp,
  faVolumeXmark,
  faWrench,
  faXmark
];
