import { NgModule } from '@angular/core';
import { RouterModule } from '@angular/router';
import { SharedLibsModule } from './shared-libs.module';
import { MaterialModule } from './material.module';

// shared pipes + directives
import { FindLanguageFromKeyPipe } from './language/find-language-from-key.pipe';
import { HasAnyAuthorityDirective } from './auth/has-any-authority.directive';
import { Logger } from './logger/logger.service';
import { LocalizedDatePipe } from './language/localized-date.pipe';
import { TruncatePipe } from '@core/util/truncate.pipe';
import { SafeResourceUrlPipe } from '@core/util/safeResourceUrl.pipe';
import { ImagePipe } from '@core/util/image.pipe';
import { VideoPipe } from '@core/util/video.pipe';
import { FormatFileSizePipe } from '@core/util/format-file-size.pipe';
import { CurrencyInputDirective } from '@core/util/currency-input.directive';
import { ScrollAnchorDirective, ScrollManagerDirective, ScrollSectionDirective } from './scroll-manager';
import { GoogleSigninButtonDirective } from '@core/util/google-signin-button.directive';
import { MatTabGroupGestureDirective } from '@core/util/mat-tab-group-gesture.directive';


// shared components
import { MediaPlayerComponent } from './media-player/media-player.component';
import { MediaRecorderComponent } from './media-recorder/media-recorder.component';
import { ConfirmDialogComponent } from './confirm-dialog/confirm-dialog.component';
import { ContenteditableValueAccessor } from './contenteditable/contenteditable-value-accessor';
import { DateSelectComponent } from './date-select/date-select.component';
import { DualSelectComponent } from './dual-select/dual-select.component';
import { ImageCarouselComponent } from './image-carousel/image-carousel.component';
import { ImageCropperComponent } from './image-cropper/image-cropper.component';
import { SpinnerComponent } from './spinner/spinner.component';
import { ReadMoreComponent } from './read-more/read-more.component';
import { PreviewLinkComponent } from './preview-link/preview-link.component';

// feature modules
import { RegisterComponent } from '@modules/account/components/register/register.component';
import { SearchComponent } from '@modules/search/components/search.component';
import { ListVisitsComponent } from '@modules/visit/components/list-visits.component';
import { ListBirthdaysComponent } from '@modules/birthday/components/list-birthdays.component';
import { BeginPostComponent } from '@modules/post/components/begin-post.component';
import { PostComponent } from '@modules/post/components/post.component';
import { ProcessPostComponent } from '@modules/post/components/process-post.component';
import { PostSettingsComponent } from '@modules/post/components/post-settings.component';
import { MediaComponent } from '@modules/post/components/media/media.component';
import { DocumentComponent } from '@modules/post/components/document/document.component';
import { CommentIconComponent } from '@modules/comment/components/comment-icon.component';
import { CommentFormComponent } from '@modules/comment/components/comment-form.component';
import { CommentComponent } from '@modules/comment/components/comment.component';
import { ListCommentsComponent } from '@modules/comment/components/list-comments.component';
import { LikeComponent } from '@modules/like/components/like.component';
import { LikeIconComponent } from '@modules/like/components/like-icon.component';
import { ListLikesComponent } from '@modules/like/components/list-likes.component';
import { ShareComponent } from '@modules/share/components/share.component';
import { ShareIconComponent } from '@modules/share/components/share-icon.component';
import { ListSharesComponent } from '@modules/share/components/list-shares.component';
import { EventComponent } from '@modules/event/components/event.component';
import { DeviceInfoService } from '@core/services/device-info.service';
import { ErrorhandlerComponent } from '@modules/errorhandler/';
import { GroupComponent } from '@modules/group/components/group.component';
import { MemberService } from '@modules/member/services/member.service';

@NgModule({
  declarations: [
    MediaRecorderComponent,
    ConfirmDialogComponent,
		CurrencyInputDirective,
    DateSelectComponent,
    ErrorhandlerComponent,
    FindLanguageFromKeyPipe,
    GoogleSigninButtonDirective,
    HasAnyAuthorityDirective,
    ImageCarouselComponent,
    ImageCropperComponent,
    LikeComponent,
    ListBirthdaysComponent,
    ListLikesComponent,
    ListSharesComponent,
    ListVisitsComponent,
    RegisterComponent,
    ScrollAnchorDirective,
    ScrollManagerDirective,
    ScrollSectionDirective,
    ShareComponent,
    TruncatePipe
  ],
  imports: [
    SharedLibsModule,
    MaterialModule,
    RouterModule,
    // standalone components
    BeginPostComponent,
    CommentComponent,
    CommentFormComponent,
    CommentIconComponent,
    ContenteditableValueAccessor,
    // DocumentComponent,
    DualSelectComponent,
    // EventComponent,
    FormatFileSizePipe,
    ImagePipe,
    LikeIconComponent,
    ListCommentsComponent,
    LocalizedDatePipe,
    MatTabGroupGestureDirective,
    MediaPlayerComponent,
    PostComponent,
    PostSettingsComponent,
    PreviewLinkComponent,
    ProcessPostComponent,
    ReadMoreComponent,
    SafeResourceUrlPipe,
    SearchComponent,
    ShareIconComponent,
    SpinnerComponent,
    // TranslateDirective,
    VideoPipe
  ],
  exports: [
    SharedLibsModule,
    MaterialModule,
    RouterModule,
    MediaPlayerComponent,
    MediaRecorderComponent,
    BeginPostComponent,
    CommentComponent,
    CommentFormComponent,
    CommentIconComponent,
    ConfirmDialogComponent,
    ContenteditableValueAccessor,
    DateSelectComponent,
    // DocumentComponent,
    DualSelectComponent,
    // EventComponent,
    FormatFileSizePipe,
    FindLanguageFromKeyPipe,
    GoogleSigninButtonDirective,
    HasAnyAuthorityDirective,
    ImageCarouselComponent,
    ImageCropperComponent,
    ImagePipe,
    LikeComponent,
    LikeIconComponent,
    ListBirthdaysComponent,
    ListCommentsComponent,
    ListLikesComponent,
    ListSharesComponent,
    ListVisitsComponent,
    LocalizedDatePipe,
    MatTabGroupGestureDirective,
    // MediaComponent,
    PostComponent,
    PostSettingsComponent,
    PreviewLinkComponent,
    ProcessPostComponent,
    ReadMoreComponent,
    RegisterComponent,
    SafeResourceUrlPipe,
    ScrollAnchorDirective,
    ScrollManagerDirective,
    ScrollSectionDirective,
    SearchComponent,
    ShareComponent,
    ShareIconComponent,
    SpinnerComponent,
    // TranslateDirective,
    TruncatePipe,
    VideoPipe,
		CurrencyInputDirective,
    ErrorhandlerComponent,

  ],
  providers: [
    Logger,
    DeviceInfoService,
    ListCommentsComponent,
  ]
})
export class SharedModule {}
