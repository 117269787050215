import { Component, OnChanges, Input, Output, EventEmitter } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { MetadataService } from '@core/services/metadata.service';

@Component({
  selector: '[xa-preview-link]',
  templateUrl: './preview-link.component.html',
  styleUrls: ['./preview-link.component.scss'],
  standalone: true,
  imports: [FontAwesomeModule, CommonModule]
})
export class PreviewLinkComponent implements OnChanges {
  @Input() text?: string;
  @Input() metadata?: any = null;
  @Input() edit?: boolean;
  @Output() metadataOut = new EventEmitter();

  constructor(
    private metadataService: MetadataService
  ) {}

  ngOnChanges() {
    if (this.text) {
      const url = this.detectUrl(this.text);
      if (url) {
        this.metadataService.getMetaDataOfUrl(new HttpParams().set('url', url)).subscribe((resp) => {
          if (resp.data) {
            this.metadata = resp.data;
            this.metadata.domain = this.detectDomain(this.metadata.url);
            this.metadataOut.emit(this.metadata);
          }
        });
      }
    }
  }

  remove() {
    this.metadataOut.emit(null);
    this.metadata = null;
  }

  private detectUrl(message: string): any {
    let regex = /(https?:\/\/)?(([a-zäöüßA-ZÄÖÜ0-9-_+]{2,})\.)?(([a-zäöüßA-ZÄÖÜ0-9-_+]{2,})\.)([a-zäöüßA-ZÄÖÜ]{2,})[\/[a-zäöüßA-ZÄÖÜ0-9-_+.?&=]{1,}]{0,}/g;
    return message.match(regex);
  }

  private detectDomain(urlStr: string): any {
    const url = new URL(urlStr);
    return url.hostname.replace("www.", '');
  }
}
