<div class="begin-post-feed" *ngIf="data.origin==='feed'">
  <img class="avatar" [src]="credential!.photoImage | image: 'avatar':'s1'"
       title="{{credential.firstName}} {{credential.lastName}}"
       alt="{{credential.firstName}} {{credential.lastName}}"/>
  <button mat-button (click)="createPost()">
    {{'post.begin' | translate}}
  </button>
</div>

<div class="begin-post-reg" *ngIf="data.origin!=='feed'">
  <button mat-button (click)="createPost()">
    {{'post.begin' | translate}}
  </button>
</div>
