<div class="dual-select">
  <div class="left">
    <div class="selection">
      {{'dualSelect.select' | translate}}
    </div>
    <ul class="items">
      <li
        *ngFor="let item of unselectedItems"
        [class.dual-select__item--selected]="pendingSelection[ item.id ]">
        <div class="item item-selectable" (click)="addToSelectedItems(item)" *ngIf="item.status!='p'">
          <img class="avatar" [src]="item.image | image: 'avatar':'s1'" title="{{item.name}}" alt={{item.name}} />
          <div class="flex-column">
            <span matLine class="name">{{item.name}}</span>
            <span matLine *ngIf="item.slogan">{{item.slogan}}</span>
          </div>
        </div>
        <div class="item" *ngIf="item.status=='p'">
          <img class="avatar" [src]="item.image | image: 'avatar':'s1'" title="{{item.name}}" alt={{item.name}} />
          <div class="flex-column">
            <span matLine class="name">{{item.name}}</span>
            <span matLine *ngIf="item.slogan">{{item.slogan}}</span>
            <span matLine>ausstehend</span>
          </div>
        </div>
      </li>
    </ul>
  </div>
  <div class="right">
    <div class="selection">
      <span>{{'dualSelect.selected' | translate: { num: selectedItems.length } }}</span>
      <a class="deselect-all" (click)="removeAllFromSelectedItems()">{{'dualSelect.deselect' | translate}}</a>
    </div>
    <ul class="items">
      <li
        *ngFor="let item of selectedItems"
        (click)="removeFromSelectedItems(item)"
        class="dual-select__item dual-select__item--new"
        [class.dual-select__item--selected]="pendingSelection[item.id]">
        <div class="item">
          <img class="avatar" [src]="item.image | image: 'avatar':'s1'" title="{{item.name}}" alt={{item.name}} />
          <div class="flex-column">
            <span matLine class="name">{{item.name}}</span>
            <span matLine *ngIf="item.slogan">{{item.slogan}}</span>
          </div>
        </div>
      </li>
    </ul>
  </div>
</div>
