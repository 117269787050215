import { Inject, Injectable, LOCALE_ID } from "@angular/core";
import { NativeDateAdapter } from "@angular/material/core";
import { Platform } from "@angular/cdk/platform";
import { getLocaleFirstDayOfWeek } from "@angular/common";
import { LuxonDateAdapter, MAT_LUXON_DATE_ADAPTER_OPTIONS, MAT_LUXON_DATE_FORMATS } from '@angular/material-luxon-adapter';

@Injectable()
export class CustomDateAdapter extends LuxonDateAdapter {
  constructor(
    @Inject(LOCALE_ID) public override locale: string
  ) {
    super(locale);
  }
  public override getFirstDayOfWeek(): any {
    return getLocaleFirstDayOfWeek(this.locale); //
  }
}
