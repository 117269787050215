<div class="snackbar-container">
  <div>
    {{'post.linkCopied' | translate}}
    <a routerLink="" (click)="forward()" class="snackbar-link">
      {{'post.viewPost' | translate}}
    </a>
  </div>
  <div class="button-container">
    <button (click)="snackBarRef.dismiss()" mat-icon-button aria-label="Clear" >
      <fa-icon icon="xmark" class="xmark"></fa-icon>
    </button>
  </div>
</div>
