<div mat-dialog-title class="dialog-title">
  <h2>Was möchten Sie melden?</h2>
  <button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <fa-icon icon="xmark" class="xmark"></fa-icon>
  </button>
</div>
<form [formGroup]="form" #ngForm="ngForm" (ngSubmit)="saveReport()" novalidate>
  <mat-dialog-content class="mat-list-report">
    <mat-selection-list formControlName="issue" [multiple]="false" class="mat-list-report">
      <mat-list-option [value]="1">
        <span matListItemTitle>{{'report.issue.1.title' | translate}}</span>
        <span matListItemLine>{{'report.issue.1.description' | translate}}</span>
      </mat-list-option>
      <mat-list-option [value]="2">
        <span matListItemTitle>{{'report.issue.2.title' | translate}}</span>
        <span matListItemLine>{{'report.issue.2.description' | translate}}</span>
      </mat-list-option>
      <mat-list-option [value]="3">
        <span matListItemTitle>{{'report.issue.3.title' | translate}}</span>
        <span matListItemLine>{{'report.issue.3.description' | translate}}</span>
      </mat-list-option>
      <mat-list-option [value]="4">
        <span matListItemTitle>{{'report.issue.4.title' | translate}}</span>
        <span matListItemLine>{{'report.issue.4.description' | translate}}</span>
      </mat-list-option>
      <mat-list-option [value]="5">
        <span matListItemTitle>{{'report.issue.5.title' | translate}}</span>
        <span matListItemLine>{{'report.issue.5.description' | translate}}</span>
      </mat-list-option>
    </mat-selection-list>
    <div class="form-group mb-4">
      <mat-label>
        {{'report.description' | translate}}
        <fa-icon icon="info-circle" class="info-circle" matTooltip="{{ 'report.description' | translate}}" matTooltipPosition="above"></fa-icon>
      </mat-label>
      <div #comment contenteditable="true" class="contenteditable"  formControlName="comment"></div>
      <div *ngIf="form.get('comment')?.hasError('required') && ngForm.submitted">
        <mat-error>{{'report.description' | translate}}</mat-error>
      </div>
    </div>
  </mat-dialog-content>
  <mat-dialog-actions align="end">
    <div class="is-submitted-wheel" *ngIf="isSubmitted"></div>
    <button mat-flat-button color="secondary" mat-dialog-close>
      Abbrechen
    </button>
    <button type="submit" mat-flat-button color="primary" [disabled]="!form.valid || isSubmitted">
      Senden
    </button>
  </mat-dialog-actions>
  <input type="hidden" formControlName="objectId" value="0">
  <input type="hidden" formControlName="objectType" value="0">
</form>
