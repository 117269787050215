import { Component, OnInit, AfterViewInit, Input, ViewChild, ElementRef, HostBinding } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { ReactiveFormsModule } from '@angular/forms';

@Component({
  selector: '[xa-media-player]',
  templateUrl: './media-player.component.html',
  styleUrls: ['./media-player.component.scss'],
  standalone: true,
  imports: [ReactiveFormsModule, FontAwesomeModule, TranslateModule, CommonModule]
})
export class MediaPlayerComponent implements AfterViewInit {
  @HostBinding('className') componentClass: string;
  @ViewChild("media") media!: ElementRef;
  @ViewChild("playBtn") playBtn!: ElementRef;
  @ViewChild("progress") progress!: ElementRef;
  @ViewChild("progressRange") progressRange!: ElementRef;
  @ViewChild("currentTime") currentTime!: ElementRef;
  @ViewChild("duration") duration!: ElementRef;
  @ViewChild("volumeIcon") volumeIcon!: ElementRef;
  @ViewChild("volume") volume!: ElementRef;
  @Input() src: any;
  mousedown = false;
  paused: boolean = true;
  volumeHigh: boolean = true;

  constructor() {
    this.componentClass = 'media-player';
  }

  ngAfterViewInit(): void {
    const displayDuration = () => {
      this.duration.nativeElement.textContent = `${this.displayTime(this.media.nativeElement.duration)}`;
    }

    if (this.media.nativeElement.readyState > 0) {
      displayDuration();
    } else {
      this.media.nativeElement.addEventListener('loadedmetadata', () => {
        displayDuration();
      });
    }

    this.playBtn.nativeElement.addEventListener("click", () => {
      if (this.paused) {
        this.paused = false;
        this.media.nativeElement.play();
      } else {
        this.paused = true;
        this.media.nativeElement.pause();
      }
    });

    this.progressRange.nativeElement.addEventListener('click', (e: any) => {
      const newTime = e.offsetX / this.progressRange.nativeElement.offsetWidth;
      this.progress.nativeElement.style.width = `${newTime * 100}%`;
      this.media.nativeElement.currentTime = newTime * this.media.nativeElement.duration;
    });

    this.media.nativeElement.addEventListener("ended", () => {
      this.paused = true;
      this.progress.nativeElement.style.width = "0%";
      this.media.nativeElement.currentTime = 0;
    });

    this.media.nativeElement.addEventListener('timeupdate', () => {
      const percent = (this.media.nativeElement.currentTime / this.media.nativeElement.duration) * 100;
      this.progress.nativeElement.style.width = `${percent}%`;
      this.currentTime.nativeElement.textContent = `${this.displayTime(this.media.nativeElement.currentTime)}`;
      this.duration.nativeElement.textContent = `${this.displayTime(this.media.nativeElement.duration)}`;
    });

    this.volumeIcon.nativeElement.addEventListener("click", () => {
      this.media.nativeElement.volume = this.media.nativeElement.volume == 0 ? 1 : 0;
      this.volume.nativeElement.value = this.media.nativeElement.volume;
       this.volume.nativeElement.value == 0 ? this.volumeHigh=false : this.volumeHigh=true;
      if (this.volume.nativeElement.value == 0) {
        this.volume.nativeElement.nextSibling.style.width = "0%";
      } else {
        this.volume.nativeElement.nextSibling.style.width = "95%";
      }
    });

    this.volume.nativeElement.addEventListener("change", () => {
      this.media.nativeElement.volume = this.volume.nativeElement.value;
      this.volume.nativeElement.value == 0 ? this.volumeHigh=false : this.volumeHigh=true;
    });

    this.progressRange.nativeElement.addEventListener("click", (e: any) => this.scrub(e));
    this.progressRange.nativeElement.addEventListener("mousemove", (e: any) => this.mousedown && this.scrub(e));
    this.progressRange.nativeElement.addEventListener("mousedown", () => (this.mousedown = true));
    this.progressRange.nativeElement.addEventListener("mouseup", () => (this.mousedown = false));
  }

  scrub(event: any): void {
    const scrubTime = (event.offsetX / this.progress.nativeElement.offsetWidth) * this.media.nativeElement.duration;
    this.media.nativeElement.currentTime = Math.floor(scrubTime);
  }

  displayTime(time: any): any {
    const minutes = Math.floor(time / 60);
    let seconds = Math.floor(time % 60);
    let _seconds = seconds > 9 ? seconds : `0${seconds}`;
    return `${minutes}:${_seconds}`;
  }
}
