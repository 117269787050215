import { Component, OnInit, AfterViewInit, Inject, RendererFactory2, ElementRef, Renderer2, ComponentRef,ComponentFactoryResolver, ViewContainerRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';

import { MaterialModule } from '@shared/material.module';
import { DeviceInfoService } from '@core/services/device-info.service';
import { ThreadModalComponent  } from './thread-modal.component';
import { AccountService } from '@modules/account/services/account.service';
import { MessageService } from '@modules/message/services/message.service';

@Component({
  selector: '[xa-message-switch]',
  templateUrl: './message-switch.component.html',
  styleUrls: ['./message-switch.component.scss'],
  standalone: true,
  imports: [CommonModule, MaterialModule ]
})

export class MessageSwitchComponent implements OnInit {
  @ViewChild("viewContainerRef", { read: ViewContainerRef }) vcr!: ViewContainerRef;
  ref!: ComponentRef<ThreadModalComponent>

  componentsReferences = new Map<number, ComponentRef<ThreadModalComponent>>();

  constructor(
    public deviceInfo: DeviceInfoService,
    public accountService: AccountService,
    private messageService: MessageService,
    private cfr: ComponentFactoryResolver,
    private renderer: Renderer2,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.messageService.sendTo.subscribe((sendTo: any) => {
      if (sendTo) {
      let credential = this.accountService.credentialValue;

      let params = new HttpParams();
      params = params.append('my', credential.profileId);
      params = params.append('to', sendTo.id);
      this.messageService.getThreadId(params).subscribe((resp: any) => {

        const url = resp.data && resp.data.threadId ? '/messages/' + resp.data.threadId : '/messages/new';
        this.router.navigate([url], { state: { open: !this.deviceInfo.screenSizes['screen-mid'],
          'my': credential.profileId, 'sendTo': sendTo.id }})

        /* FURTHER USE
        if (this.deviceInfo.screenSizes['screen-mid']) {
          if (this.componentsReferences.has(sendTo.id)) return;
          let componentFactory = this.cfr.resolveComponentFactory(ThreadModalComponent);
          let childComponentRef = this.vcr.createComponent(componentFactory);
          childComponentRef.setInput('threadId', resp.data.threadId);
          childComponentRef.setInput('sendToId', sendTo.id);
          let childComponent = childComponentRef.instance;
          childComponent.messageSwitchRef = this;

          const pos = (this.componentsReferences.size * 400) + 50
          this.renderer.setStyle(childComponentRef.location.nativeElement, "right", pos + "px");

          // add reference for newly created component
          this.componentsReferences.set(sendTo.id, childComponentRef);
        } else {
          const url = resp.data && resp.data.threadId ? '/messages/' + resp.data.threadId : '/messages/new';
          this.router.navigate([url], { state: { 'my': credential.profileId, 'sendTo': sendTo.id }})
        }
        */
      });
      }
    });
  }

  remove(key: number) {
    if (this.vcr.length < 1) return;

    let componentRef = this.componentsReferences.get(key);
    let vcrIndex: number = this.vcr.indexOf(componentRef!.hostView);
    // removing component from container
    this.vcr.remove(vcrIndex);

    // removing component from the list
    this.componentsReferences.delete(key);

    for (let [index, componentsReference] of this.componentsReferences.entries()) {
      const pos = (index * 400) + 50;
      this.renderer.setStyle(componentsReference.location.nativeElement, "right", pos+"px");
    }
  }

}
