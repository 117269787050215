import { Component, Input, ViewChild, ElementRef, Renderer2, OnChanges, SimpleChanges } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { DateTime } from 'luxon';

import { MaterialModule } from '@shared/material.module';
import { Logger } from '@shared/logger/logger.service';
import { Credential } from '@modules/account/models/credential.model';
import { AccountService } from '@modules/account/services/account.service';
import { CommentService } from '@modules/comment/services/comment.service';
import { CommentInterface } from '@modules/comment/models/comment.interface';

import { ActiveCommentInterface } from './activeComment.interface';
import { CommentComponent } from './comment.component';
import { CommentFormComponent } from './comment-form.component';

@Component({
  selector: '[xa-list-comments]',
  templateUrl: './list-comments.component.html',
  styleUrls: ['./list-comments.component.scss'],
  standalone: true,
  imports: [CommentComponent, CommentFormComponent, MaterialModule, CommonModule]
})
export class ListCommentsComponent implements OnChanges {
  @ViewChild('div') div: ElementRef;
  @Input() origin: string;
  @Input() id: number;
  @Input() expansionId: number;
  @Input() commentedById: number;

  credential: Credential;
  // comments: CommentInterface[] = [];
  comments: any = [];
  activeComment: ActiveCommentInterface | null = null;
  rootComments: any;
  fxFlexWidth: number;

  constructor(
    private accountService: AccountService,
    private commentService: CommentService,
    private logger: Logger,
    private renderer: Renderer2
  ) {
    this.credential = this.accountService.credentialValue;
    if (this.origin == "event") {
      this.renderer.addClass(this.div, 'event-tab-x');
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes['expansionId'].currentValue==this.id) {
      let params = new HttpParams();
      params = params.append('id', this.id);
      params = params.append('type', this.origin);
      this.commentService.getComments(params).subscribe(resp => {
          this.comments.push(...resp.data);
        },
        error => {
          this.logger.log(error);
        });
    }
  }

  getRootComments(): CommentInterface[] {
    return this.comments.filter((comment: any) => comment.commentableType !== 'comment');
  }

  addComment({
    text,
    commentableId,
    commentableType,
  }: {
    text: string;
    commentableId: number | null;
    commentableType: string | null;
  }): void {
    const comment: any = {
      commentableId: commentableId,
      commentableType: commentableType,
      commentedById: this.credential.profileId,
      text: text,
    }

    this.commentService.saveComment(comment).subscribe((resp: any) => {
        const commentedBy: any = {
          firstName: this.credential.firstName,
          lastName: this.credential.lastName,
          slug: this.credential.slug,
          slogan: this.credential.slogan,
          photoImage: this.credential.photoImage
        }
        comment.id = resp.data.id;
        comment.createdAt = resp.data.createdAt;
        comment.commentedBy = commentedBy;
        this.comments.unshift(comment);
        this.activeComment = null;
      });
  }

  updateComment({
    text,
    commentId,
  }: {
    text: string;
    commentId: number;
  }): void {

    const comment: any = {
      id: commentId,
      text: text
    }
    this.commentService
      .saveComment(comment)
      .subscribe((updatedComment: any) => {
        this.comments = this.comments.map((_comment: any) => {
          if (_comment.id==commentId) {
            _comment.text = comment.text;
          }
          return _comment;
        });
        this.activeComment = null;
      });
  }

  deleteComment(commentId: number): void {
    let params = new HttpParams();
    params = params.append('id', commentId);
    this.commentService.deleteComment(params).subscribe(() => {
      this.comments = this.comments.filter(
        (comment: any) => comment.id !== commentId
      );
    });
  }

  reportComment(commentId: number): void {
    let params = new HttpParams();
    params = params.append('id', commentId);
    /* this.commentService.deleteComment(params).subscribe(() => {
      this.comments = this.comments.filter(
        (comment: any) => comment.id !== commentId
      );
    }); */
  }

  setActiveComment(activeComment: ActiveCommentInterface | null): void {
    this.activeComment = activeComment;
  }


  getReplies(commentId: number): CommentInterface[] {
    return this.comments
      .filter((comment: any) => comment.commentableId==commentId)
      .sort(
        (a: any, b: any) =>
        new Date(a.createdAt).getTime() - new Date(b.createdAt).getTime()
      );
  }
}
