<div class="flex-row comment-item">
  <div class="avatar">
    <a routerLink="/profile/{{comment.commentedBy.slug}}">
      <img [src]="comment.commentedBy.photoImage | image: 'avatar':'s1'" title="{{comment.commentedBy.firstName}} {{comment.commentedBy.lastName}}" alt="{{comment.commentedBy.firstName}} {{comment.commentedBy.lastName}}" />
    </a>
  </div>
  <div class="comment-container">
    <div class="comment-content">
      <div class="fl">
        <div>
          <a routerLink="/profile/{{comment.commentedBy.slug}}">
            <div>{{comment.commentedBy.firstName}} {{comment.commentedBy.lastName}}</div>
          </a>
          <div class="small" *ngIf="comment.commentedBy.slogan">{{comment.commentedBy.slogan}}</div>
        </div>
        <div class="mat-menu">
          <mat-menu #eventMenu="matMenu">
            <ng-template matMenuContent>
              <a mat-menu-item (click)="reportComment.emit(comment.id)">{{'comment.report' | translate}}</a>
              <a mat-menu-item (click)="setActiveComment.emit({id: comment.id,type: activeCommentType.editing})" *ngIf="canEdit">{{'comment.edit' | translate}}</a>
              <a mat-menu-item (click)="deleteComment.emit(comment.id)" *ngIf="canDelete">{{'comment.delete' | translate}}</a>
            </ng-template>
          </mat-menu>
          <div class="small">{{comment.createdAt | localizedDate: 'DATETIME_SHORT'}}</div>
          <button class="action-button-rounded" [matMenuTriggerFor]="eventMenu" Event>
            <fa-icon icon="ellipsis-h"></fa-icon>
          </button>
        </div>
      </div>
      <div class="margin-comment" *ngIf="!isEditing()" [innerHTML]="comment.text"></div>
    </div>
    <div xa-comment-form *ngIf="isEditing()" submitLabel="Update" [hasCancelButton]="true" [isEditing]="true" [initialText]="comment.text" (handleSubmit)="
        updateComment.emit({ text: $event, commentId: comment.id, commentableType: comment.commentableType })
      " (handleCancel)="setActiveComment.emit(null)"></div>
    <div class="action-icons">
      <div class="comment-action" *ngIf="canReply"  (click)="
          setActiveComment.emit({
            id: comment.id,
            type: activeCommentType.replying
          })
        ">
        <div>
          <fa-icon [icon]="['far', 'comment']"></fa-icon>
          <span *ngIf="deviceInfo.screenSizes['screen-mid']">{{'action.answer' | translate}}</span>
        </div>
      </div>
    </div>
  </div>
</div>
<div class="comment-form">
  <div xa-comment-form *ngIf="isReplying()" submitLabel="Reply" [isEditing]="false" (handleSubmit)="addComment.emit({ text: $event, commentableId: replyId, commentableType: replyType })"></div>
  <div class="replies" *ngIf="replies.length > 0">
    <div xa-comment *ngFor="let reply of replies" [comment]="reply" (setActiveComment)="setActiveComment.emit($event)" [activeComment]="activeComment" (updateComment)="updateComment.emit($event)" (deleteComment)="deleteComment.emit($event)" (addComment)="addComment.emit($event)" [commentableId]="comment.id" [commentableType]="comment.commentableType" [replies]="[]" [commentedById]="comment.commentedById"></div>
  </div>
</div>

