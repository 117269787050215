<div mat-dialog-title class="dialog-title">
  <h2>{{'post.document.select.title' | translate}}</h2>
  <button mat-icon-button aria-label="close dialog" mat-dialog-close>
    <fa-icon icon="xmark" class="xmark"></fa-icon>
  </button>
</div>
<div mat-dialog-content>
  <div style="height:500px" *ngIf="files.length != 0 && files[0].type=='application/pdf'">
    <ng2-pdfjs-viewer [pdfSrc]="files[0].urlAB"></ng2-pdfjs-viewer>
  </div>
  <div *ngIf="state=='loading'">{{'post.upload.processing' | translate}}</div>
  <div *ngIf="files.length == 0 && state!='loading'">
    <a (click)="dummyClick.click()" style="color: #3f51b5;">{{'post.document.select.label' | translate}}</a>
  </div>
  <input type="file" hidden #dummyClick (change)="onSelectFiles($event)" accept=".pdf"  />
</div>
<div mat-dialog-actions align="end">
  <button mat-flat-button mat-dialog-close>
    {{'entity.action.cancel' | translate}}
  </button>
  <button type="submit" (click)="done()" mat-flat-button color="primary" [disabled]="isSubmitted">
    {{'entity.action.done' | translate}}
  </button>
</div>
