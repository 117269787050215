import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ApiService } from '@core/services/api.service';
// import { Visit } from '@modules/visit/models/visit.model';

@Injectable({
  providedIn: 'root'
})

export class ShareService {
  constructor(
    private apiService: ApiService
  ) { }

  getShares(params : HttpParams): Observable<any> {
    return this.apiService.get('/shares', params);
  }

  saveShare(share: any): Observable<{}> {
    return this.apiService.post('/shares/save', share);
  }
}
