import { Component } from '@angular/core';
import { Router } from '@angular/router';

import { ErrorService } from '@core/services/error.service';

@Component({
  template: ''
})
export class WrongRouteComponent {
  constructor(
    private readonly router: Router,
    private readonly errorService: ErrorService
  ) {
   this.errorService.addError({
     code: '404',
     message: 'Page not found',
   });
   this.router.navigateByUrl('/error', {skipLocationChange: true});
  }

}
