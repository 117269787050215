import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { HttpParams } from '@angular/common/http';

import { Post } from '@modules/post/models/post.model';
import { ApiService } from '@core/services/api.service';

@Injectable({
  providedIn: 'root'
})

export class PostService {
  constructor(
    private apiService: ApiService
  ) { }


  getPosts(params : HttpParams): Observable<any> {
	  return this.apiService.get('/posts', params);
  }

  getPost(id: number, seo: boolean, params: HttpParams): Observable<any> {
    const url = seo == true ? `/posts/seo/${id}` : `/posts/${id}`;
    return this.apiService.get(url, params);
  }

  savePost(post: Post): Observable<any> {
    return this.apiService.post('/posts/save', post);
  }

  deletePost(params : HttpParams): Observable<any> {
    return this.apiService.delete('/posts/delete/', params);
  }
}
