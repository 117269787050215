import { Component, OnDestroy, ChangeDetectionStrategy, ChangeDetectorRef, EventEmitter, Output, HostBinding } from '@angular/core';
import { MediaRecordingService } from './media-recording.service';

@Component({
  selector: '[xa-media-recorder]',
  templateUrl: './media-recorder.component.html',
  styleUrls: ['./media-recorder.component.scss'],
  changeDetection: ChangeDetectionStrategy.Default
})
export class MediaRecorderComponent implements OnDestroy {
  @HostBinding('className') componentClass: string;
  @Output() mediaBlobOut = new EventEmitter()
  isMediaRecording = false;
  mediaRecordedTime: any;
  audioBlob: any = null;
   mediaBlobUrl: any;

  constructor(
    private ref: ChangeDetectorRef,
    private mediaRecordingService: MediaRecordingService,
  ) {
    this.componentClass = 'media-recorder';

    this.mediaRecordingService.recordingFailed().subscribe(() => {
      this.isMediaRecording = false;
      this.ref.detectChanges();
    });

    this.mediaRecordingService.getRecordedTime().subscribe((time) => {
      this.mediaRecordedTime = time;
      this.ref.detectChanges();
    });

    this.mediaRecordingService.getRecordedBlob().subscribe((blob) => {
      this.audioBlob = blob;
      this. mediaBlobUrl = URL.createObjectURL(blob);
      this.ref.detectChanges();
    });
  }

  startMediaRecording() {
    if (!this.isMediaRecording) {
      this.isMediaRecording = true;
      this.mediaRecordingService.startRecording();
    }
  }

  abortMediaRecording() {
    if (this.isMediaRecording) {
      this.isMediaRecording = false;
      this.mediaRecordingService.abortRecording();
    }
  }

  stopMediaRecording() {
    if (this.isMediaRecording) {
      this.mediaRecordingService.stopRecording();
      this.isMediaRecording = false;
    }
  }

  clearMediaRecordedData() {
    this. mediaBlobUrl = null;
    this.mediaBlobOut.emit({'audioBlob': null});
  }

  sendMessage() {
    this.mediaBlobOut.emit({'audioBlob': this.audioBlob});
  }

  ngOnDestroy(): void {
    this.abortMediaRecording();
  }
}

