/* eslint-disable @typescript-eslint/no-unsafe-return */
// https://github.com/gothinkster/angular-realworld-example-app/blob/master/src/app/core/services/api.service.ts

import { Injectable } from '@angular/core';
import { HttpClient, HttpParams, HttpHeaders } from '@angular/common/http';
import { Observable ,  throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import axios, { AxiosResponse } from 'axios';

import { environment } from '@environments/environment';
import { Logger } from '@shared/logger/logger.service';
// import { JwtService } from './jwt.service';

@Injectable({
  providedIn: 'root'
})

export class ApiService {
  constructor(
    private http: HttpClient,
    private logger: Logger
    // private jwtService: JwtService
  ) {}

  get(path: string, params?: HttpParams): Observable<any> {
    return this.http.get(`${environment.apiUrl}${path}`, { params })
      .pipe(catchError(this.handleError));
  }

  getCdn(path: string, params?: HttpParams): Observable<any> {
    return this.http.get(`${environment.cdnUrl}${path}`, { params })
      .pipe(catchError(this.handleError));
  }

  put(path: string, body: unknown = {}): Observable<any> {
    return this.http.put(`${environment.apiUrl}${path}`, body)
      .pipe(catchError(this.handleError));
  }

  post(path: string, body: unknown = {}): Observable<any> {
	  const headers = { 'content-type': 'application/json'}  ;
    return this.http.post(`${environment.apiUrl}${path}`, body, {headers})
      .pipe(catchError(this.handleError));
  }

  postUpload(path: string, data: any): Observable<any> {
    return this.http.post(`${environment.apiUrl}${path}`, data)
      .pipe(catchError(this.handleError));
  }

  delete(path: string, params?: HttpParams): Observable<any> {
    return this.http.delete(`${environment.apiUrl}${path}`, { params })
      .pipe(catchError(this.handleError));
  }

  private handleError(error: any) : Observable<any> {
    // this.logger.log(error);
    console.error(error);
    // return  throwError(error.error);
    return  throwError(error);
  }


}
