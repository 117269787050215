import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ApiService } from '@core/services/api.service';

@Injectable({
  providedIn: 'root'
})
export class FileUploadService {
  constructor(
    private apiService: ApiService,
  ) { }

  upload(formData: any): Observable<any> {
    return this.apiService.postUpload('/upload', formData);
  }
}
