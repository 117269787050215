import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'xa-confirm-dialog',
  templateUrl: './confirm-dialog.component.html',
  styleUrls: ['./confirm-dialog.component.scss']
})
export class ConfirmDialogComponent {
  title: string;
  message: string;
  closeBtn : string;
  confirmBtn : any;

  constructor(
    private translate: TranslateService,
    public dialogRef: MatDialogRef<ConfirmDialogComponent>,
    @Inject(MAT_DIALOG_DATA) public data: {title: string, message: string, button: any | null}
  ) {
    this.title = data.title;
    this.message = data.message;
    this.closeBtn = data.button ? this.translate.instant('entity.action.cancel') : this.translate.instant('entity.action.close');
    this.confirmBtn = data.button;
  }

  onConfirm(): void {
    this.dialogRef.close(true);
  }
}
