import { Component, OnInit, HostBinding, ViewChild, ElementRef, Renderer2, EventEmitter, Input, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';

import { MaterialModule } from '@shared/material.module';
import { AccountService } from '@modules/account/services/account.service';
import { DeviceInfoService } from '@core/services/device-info.service';
import { ShareService } from '@modules/share/services/share.service';
import { Credential } from '@modules/account/models/credential.model';

@Component({
  selector: '[xa-share-icon]',
  templateUrl: './share-icon.component.html',
  styleUrls: ['./share-icon.component.scss'],
  standalone: true,
  imports: [FontAwesomeModule, MaterialModule, TranslateModule, CommonModule]
})
export class ShareIconComponent implements OnInit {
  @HostBinding('className') componentClass: string;
  @ViewChild("share") share: ElementRef;
  @Input() sharedId: number;
  @Input() sharedType: string;
  @Output() isSharedChange = new EventEmitter()
  credential: Credential;

  constructor(
    private accountService: AccountService,
    private shareService: ShareService,
    private renderer: Renderer2,
    public deviceInfo: DeviceInfoService
  ) {
    this.componentClass = 'xa-share-icon';
  }

  ngOnInit(): void {
    this.credential = this.accountService.credentialValue;
  }

  inFeed(): void {
    const share: any = {
      sharedId: this.sharedId,
      sharedType: this.sharedType,
      sharedAt: new Date(),
      sharedById: this.credential.profileId,
      shareTo: 'c'
    }
    this.shareService.saveShare(share).subscribe(resp => {
      this.isSharedChange.emit(true);
    });
  }

  inFeedWithComment(): void {}

  asMessage(): void {}
}
