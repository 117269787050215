import { IProfile } from '@modules/profile/models/profile.model';
import { Profile } from '@modules/profile/models/profile.model';

export interface IMessage {
  id?: number;
  threadId?: string;
  sender?: IProfile;
  recipient?: IProfile;
  textMsg?: string;
  audioMsg?: string;
  sendedAt?: any; // Date => because of DateTime.local
  viewedAt?: Date;
  recipients?: any;
  files?: any;
}

export class Message implements IMessage {
  constructor(
    public id?: number,
    public threadId?: string,
    public sender?: Profile,
    public recipient?: Profile,
    public textMsg?: string,
    public audioMsg?: string,
    public sendedAt?: any,
    public viewedAt?: Date,
    public recipients?: any,
    public files?: any
  ) {}
}
