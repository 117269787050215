import { Component, Input, ElementRef, ViewChild, OnInit, AfterViewInit, AfterContentChecked, ChangeDetectorRef } from '@angular/core';
import { CommonModule } from '@angular/common';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: '[xa-read-more]',
  templateUrl: './read-more.component.html',
  styleUrls: ['./read-more.component.scss'],
  standalone: true,
  imports: [CommonModule]
})
export class ReadMoreComponent implements OnInit, AfterViewInit, AfterContentChecked {
  @ViewChild("textContainer") textContainer: ElementRef;
  @Input() text: string;
  anchor: string = this.translate.instant('global.show.more');
  contentHeight: any;
  readMore: any;
  expand: any;

  constructor(
    private translate: TranslateService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.cdr.detach();
  }

  ngAfterViewInit() {
    this.contentHeight = this.textContainer.nativeElement.offsetHeight;
    if (this.contentHeight > 63) {
      this.readMore = true;
      this.expand = false;
    }
    else {
      this.readMore = false;
      this.expand = null;
    }
  }

  ngAfterContentChecked(): void {
    this.cdr.detectChanges();
  }

  toggle(): void {
    this.expand = !this.expand;
    this.anchor = this.expand ? this.translate.instant('global.show.less') : this.translate.instant('global.show.more');
  }
}
