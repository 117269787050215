<mat-expansion-panel [ngClass]="origin=='event' ? 'origin-event comments-panel': 'comments-panel'"
                     [expanded]="id == expansionId" class="mat-elevation-z" disabled="true">
  <div class="comments">
    <div xa-comment-form submitLabel="Write"
      (handleSubmit)="addComment({ text: $event, commentableId: id, commentableType: origin })">
    </div>
    <div class="comments-container">
      <div xa-comment
        *ngFor="let rootComment of getRootComments()"
        [comment]="rootComment"
        [replies]="getReplies(rootComment.id)"
        [activeComment]="activeComment"
        (setActiveComment)="setActiveComment($event)"
        (addComment)="addComment($event)"
        (updateComment)="updateComment($event)"
        (deleteComment)="deleteComment($event)"
        (reportComment)="reportComment($event)"
        [commentedById]="commentedById">
      </div>
    </div>
  </div>
</mat-expansion-panel>
