<div #headerContainer class="header-container">
  <div xa-header [unreadedMessages]="unreadedMessages"
             [contactRequests]="contactRequests"
             [numNotifications]="numNotifications">
  </div>
</div>
<div class="body-container">
  <div class="centered-container mbo">
    <router-outlet></router-outlet>
  </div>
  <div #footerContainer  class="footer-container">
    <div xa-footer [unreadedMessages]="unreadedMessages"
               [contactRequests]="contactRequests"
               [numNotifications]="numNotifications">
    </div>
  </div>
</div>
<div xa-message-switch></div>
