import { Pipe, PipeTransform } from '@angular/core';

import { environment } from '@environments/environment';

@Pipe({
  name: 'image',
  standalone: true
})
export class ImagePipe implements PipeTransform {
  defaultImage = "";
  transform(image: string, type: string, size?: string): string {
    if (!image) {
      switch (type) {
        case 'avatar': {
          this.defaultImage = '/assets/images/people.svg';
          break;
        }
        case 'group': {
          this.defaultImage = '/assets/images/group.svg';
          break;
        }
        case 'event': {
          this.defaultImage = '/assets/images/event.svg';
          break;
        }
        case 'organization': {
          this.defaultImage = '/assets/images/organization.svg';
          break;
        }
        case 'background': {
          this.defaultImage = '/assets/images/background.jpg';
          break;
        }
        default: {
          break;
        }
      }
    } else if (image && size) {
      image = image.concat(size);
    }
    return image ? environment.cdnUrl.concat(image) : environment.appUrl.concat(this.defaultImage);
  }
}
