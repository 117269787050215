import {Injectable, Renderer2, RendererFactory2} from '@angular/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';

@Injectable({
  providedIn: 'root'
})
export class ColorSchemeService {

  private renderer: Renderer2;
  private colorScheme: string;
  // Define prefix for clearer and more readable class names in scss files
  private colorSchemePrefix = ''; // ''color-scheme-';

  constructor(
    private rendererFactory: RendererFactory2,
    private cookieService: SsrCookieService
  ) {
    // Create new renderer from renderFactory, to make it possible to use renderer2 in a service
    this.renderer = rendererFactory.createRenderer(null, null);
  }

  detectPrefersColorScheme() {
    // Detect if prefers-color-scheme is supported
    if (window.matchMedia('(prefers-color-scheme)').media !== 'not all') {
      // Set colorScheme to Dark if prefers-color-scheme is dark. Otherwise, set it to Light.
      this.colorScheme = window.matchMedia('(prefers-color-scheme: dark)').matches ? 'dark-theme' : 'light-theme';
    } else {
      // If the browser does not support prefers-color-scheme, set the default to dark.
      this.colorScheme = 'dark-theme';
    }
  }

  setColorScheme(scheme: string) {
    this.colorScheme = scheme;
    // Save prefers-color-scheme to cookie
    this.cookieService.set('theme', scheme, 999, '/');
  }

  getColorScheme() {
    const cookieColorScheme = this.cookieService.get('theme');
    // Check if any prefers-color-scheme is stored in cookie
    if (cookieColorScheme) {
      // Save prefers-color-scheme from cookie
      this.colorScheme = cookieColorScheme;
    } else {
      // If no prefers-color-scheme is stored as cookie, try to detect OS default prefers-color-scheme
      this.detectPrefersColorScheme();
    }
  }

  load() {
    this.getColorScheme();
    this.renderer.addClass(document.body, this.colorSchemePrefix + this.colorScheme);
  }

  update(scheme: string) {
    this.setColorScheme(scheme);
    // Remove the old color-scheme class
    this.renderer.removeClass(document.body, this.colorScheme=='dark-theme' ? 'light-theme' : 'dark-theme');
    // Add the new / current color-scheme class
    this.renderer.addClass(document.body, scheme);
  }

  currentActive() {
    return this.colorScheme;
  }

}
