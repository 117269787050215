<div *ngIf="viewable=='post'">
  <form [formGroup]="form" novalidate>
    <div mat-dialog-title class="dialog-title">
      <h2 *ngIf="state=='new'">{{'post.new' | translate}}</h2>
      <h2 *ngIf="state=='edit'">{{'post.edit' | translate}}</h2>
      <button mat-icon-button aria-label="close dialog" mat-dialog-close>
        <fa-icon icon="xmark" class="xmark"></fa-icon>
      </button>
    </div>
    <div mat-dialog-content>
      <a class="flex-row" (click)="togglePostSettings()">
        <div class="avatar">
          <img [src]="credential.photoImage! | image: 'avatar':'s2'" title="{{credential.firstName}} {{credential.lastName}}" alt="{{credential.firstName}} {{credential.lastName}}" />
        </div>
        <div>
          <div class="name">{{credential.firstName}} {{credential.lastName}}</div>
          <div class="visibility" *ngIf="origin == 'feed'">
            <div *ngIf="!settings.name">
              {{ ('post.visibility.' + settings.visibility) | translate}}
            </div>
            <div *ngIf="settings.name">
              {{ ('post.visibility.' + settings.visibility) | translate: { name: settings.name } }}
            </div>
            <fa-icon icon="chevron-down" ></fa-icon>
          </div>
        </div>
      </a>
      <div *ngIf="origin == 'group'">
        <mat-form-field dense>
          <input matInput formControlName="subject" placeholder="{{'post.form.subject' | translate}}">
        </mat-form-field>
        <mat-checkbox matInput labelPosition="after" required="false" formControlName="modPost" *ngIf="memStat >= 10">
          <span>{{'post.modPost' | translate}}</span>
        </mat-checkbox>
        <div formControlName="text" #text attr.placeholder="{{'post.form.text' | translate}}" contenteditable="true" class="contenteditable" style="height:200px"></div>
        <mat-form-field>
          <mat-select formControlName="forumId" [disableOptionCentering]="true" placeholder="{{'post.forum.select' | translate}}">
            <mat-option *ngFor="let forum of forums" [value]="forum.id">
              {{ forum.name }}
            </mat-option>
          </mat-select>
        </mat-form-field>
        <input hidden="true" matInput formControlName="groupId">
      </div>
      <div *ngIf="origin != 'group'">
        <mat-form-field dense *ngIf="settings.groupId">
          <input matInput formControlName="subject" placeholder="{{'post.form.subject' | translate}}">
        </mat-form-field>
        <div formControlName="text" #text attr.placeholder="{{'post.form.text' | translate}}" contenteditable="true" class="contenteditable" style="height:200px"></div>
      </div>
      <emoji-mart class="emoji-mart" set="twitter" [showPreview]="false" [enableSearch]="false" (emojiSelect)="addEmoji($event)" *ngIf="showEmojiPicker"></emoji-mart>
      <p *ngIf="video"><video [src]="video.url" style="width:100%" controls></video></p>
      <div *ngIf="document">
        <ng2-pdfjs-viewer [pdfSrc]="document.urlAB"></ng2-pdfjs-viewer>
      </div>
      <div style="position: relative">
        <div class="image-grid images-{{numImages}}" *ngIf="images">
          <div *ngFor="let image of images.slice(0, 5)">
            <img [src]="image.url">
          </div>
        </div>
        <div class="image-plus" *ngIf="images && images.length > 5">
          + {{images.length - 5}}
        </div>
      </div>
      <div xa-preview-link [text]="fldTextValue" [edit]="true" (metadataOut)="setMetadata($event)" *ngIf="fldTextValue && !video"></div>
      <div class="add-to-post">
        <button (click)="toggleMedia()" mat-icon-button matTooltip="{{'post.button.addToContent.image' | translate}}"
                matTooltipPosition="above" *ngIf="state=='new'">
          <fa-icon class="icon" icon="image" matTooltip="{{'group.name.tooltip' | translate}}" matTooltipPosition="above"></fa-icon>
        </button>
        <button (click)="toggleDocument()" mat-icon-button matTooltip="{{'post.button.addToContent.document' | translate}}"
                matTooltipPosition="above" *ngIf="state=='new'">
          <fa-icon class="icon" icon="file-lines"></fa-icon>
        </button>
        <button (click)="toggleEmojiPicker()" mat-icon-button matTooltip="{{'post.button.addToContent.emoji' | translate}}"
                matTooltipPosition="above">
          <fa-icon class="icon" icon="face-smile"></fa-icon>
        </button>
      </div>
    </div>
    <div mat-dialog-actions align="end">
      <div class="is-submitted-wheel" *ngIf="isSubmitted"></div>
      <button mat-flat-button mat-dialog-close>
        {{'entity.action.cancel' | translate}}
      </button>
      <button type="submit" (click)="savePost()" mat-flat-button color="primary">
        {{'entity.action.save' | translate}}
      </button>
    </div>
    <input hidden="true" matInput formControlName="authorId">
  </form>
</div>
<div *ngIf="viewable=='media'">
  <div xa-media (selectedMediaFiles)="eventHandlerMedia($event)"></div>
</div>
<div *ngIf="viewable=='document'">
  <div xa-document (selectedDocument)="eventHandlerDocument($event)"></div>
</div>
<div *ngIf="viewable=='post-settings'">
  <div style="height:400px" xa-post-settings (selectedSettings)="eventHandlerSettings($event)" [settings]="settings"></div>
</div>
