import { Component, Inject } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { MatDialog, MatDialogConfig, MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

import { ShareService } from '@modules/share/services/share.service';

@Component({
  selector: 'xa-list-shares',
  templateUrl: './list-shares.component.html',
  styleUrls: ['./list-shares.component.scss']
})
export class ListSharesComponent {
  shares: any;

  constructor(
    @Inject(MAT_DIALOG_DATA) data: {sharedId: number, sharedType: string},
    private dialog: MatDialog,
    private shareService: ShareService,
  ) {
    let params = new HttpParams();
    params = params.append('sharedId', data.sharedId);
    params = params.append('sharedType', data.sharedType);
    this.shareService.getShares(params).subscribe(resp => {
      this.shares = resp.data.rows;
    });
  }
}
