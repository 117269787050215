<div *ngIf="metadata" class="metadata">
  <a href="{{metadata.url}}" target="_blank" rel="noopener noreferrer">
    <div class="meta-image">
      <img [src]="metadata.image" alt="{{metadata.title}}" *ngIf="metadata.image" />
      <img [src]="metadata.icon" alt="{{metadata.title}}" *ngIf="!metadata.image && metadata.icon" />
    </div>
    <div class="metadata-content">
      <div class="title">{{metadata.title}}</div>
      <div class="description">{{metadata.description}}</div>
      <div class="domain">{{metadata.domain}}</div>
    </div>
  </a>
  <button (click)="remove()" *ngIf="edit">
    <fa-icon icon="xmark" class="xmark"></fa-icon>
  </button>
</div>
