import { Component, OnInit, ElementRef, HostListener, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { HttpParams } from '@angular/common/http';
import { RouterModule, Router, NavigationEnd } from '@angular/router';
import { OverlayModule } from '@angular/cdk/overlay';
import { TranslateModule } from '@ngx-translate/core';
import { Subject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { SearchService } from '@modules/search/services/search.service';
import { ImagePipe } from '@core/util/image.pipe';

@Component({
  selector: '[xa-search]',
  templateUrl: './search.component.html',
  styleUrls: ['./search.component.scss'],
  standalone: true,
  imports: [ImagePipe, OverlayModule, FontAwesomeModule, TranslateModule, RouterModule, CommonModule]
})
export class SearchComponent implements OnInit {
  @ViewChild('searchInput') searchInput: ElementRef;
  private searchTerm = new Subject<string>();
  items: any;
  isOpen = false;

  constructor(
    private router: Router,
    private elem: ElementRef,
    private searchService: SearchService
  ) {}

  ngOnInit(): void {
    this.searchTerm.pipe(debounceTime(300)).subscribe((searchTerm: string) => {
      if (searchTerm.length > 0) {
        const params = this.getHttpParams(searchTerm);
        this.searchService.search(params).subscribe(resp => {
            this.items = resp.data;
            this.isOpen = this.items.length > 0 ? true : false;
          },
          error => {
            // this.logger.log(error);
          });

      } else {
        this.items = [];
        this.isOpen = false;
      }
    });
    this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd) {
        this.searchInput.nativeElement.value = '';
        this.items = [];
        this.isOpen = false
      }
    });
  }

  @HostListener('document:click', ['$event'])
  click(event: Event) {
    if (this.elem.nativeElement.contains(event.target) && this.items.length > 0) {
      this.isOpen = true;
    }
    else {
      this.isOpen = false;
    }
  }

  search(searchTerm: any): void {
    this.searchTerm.next(searchTerm.target.value);
  }

  private getHttpParams(term: string) {
    let params = new HttpParams();
    params = params.append('term', term);
    return params;
  }
}
