<div class="item-search">
  <fa-icon icon="search"></fa-icon>
  <input #searchInput autocomplete="off"
         (input)="search($event)"
         placeholder="{{'search.placeholder' | translate}}"
         cdkOverlayOrigin
         #trigger="cdkOverlayOrigin">
</div>

<ng-template cdkConnectedOverlay [cdkConnectedOverlayOrigin]="trigger" [cdkConnectedOverlayOpen]="isOpen">
  <ul class="item-list">
    <li *ngFor="let item of items">
      <ng-template [ngIf]="item.type=='profile'">
        <a routerLink="/profile/{{item.slug}}">
          <div style="display:flex">
            <img [src]="item.image | image: 'avatar':'s1'" class="avatar" alt="logo" width="30" />
            <div>{{item.name}}</div>
          </div>
        </a>
      </ng-template>
      <ng-template [ngIf]="item.type=='group'">
        <a routerLink="/groups/{{item.slug}}">
          <div style="display:flex">
            <img [src]="item.image | image: 'group':'s1'" class="avatar" alt="logo" width="30" />
            <div>
              <div>{{item.name}}</div>
              <div class="small-label">{{'search.type.group' | translate}}</div>
            </div>
          </div>
        </a>
      </ng-template>
      <ng-template [ngIf]="item.type=='event'">
        <a routerLink="/events/{{item.slug}}">
          <div style="display:flex">
            <img [src]="item.image | image: 'event':'s1'" class="avatar" alt="logo" width="30" />
            <div>
              <div>{{item.name}}</div>
              <div class="small-label">{{'search.type.event' | translate}}</div>
            </div>
          </div>
        </a>
      </ng-template>
      <ng-template [ngIf]="item.type=='organization'">
        <a routerLink="/organizations/{{item.slug}}">
          <div style="display:flex">
            <img [src]="item.image | image: 'organization':'s1'" class="avatar" alt="logo" width="30" />
            <div>
              <div>{{item.name}}</div>
              <div class="small-label">{{'search.type.organization' | translate}}</div>
            </div>
          </div>
        </a>
      </ng-template>
    </li>
  </ul>
</ng-template>
