import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { DateTime } from 'luxon';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';

import { MaterialModule } from '@shared/material.module';
import { LocalizedDatePipe } from '@shared/language/localized-date.pipe';
import { AccountService } from '@modules/account/services/account.service';
import { DeviceInfoService } from '@core/services/device-info.service';
import { ImagePipe } from '@core/util/image.pipe';
import { Credential } from '@modules/account/models/credential.model';
import { CommentInterface } from '@modules/comment/models/comment.interface';

import { ActiveCommentInterface } from './activeComment.interface';
import { ActiveCommentTypeEnum } from './activeCommentType.enum';
import { CommentFormComponent } from './comment-form.component';

@Component({
  selector: '[xa-comment]',
  templateUrl: './comment.component.html',
  styleUrls: ['./comment.component.scss'],
  standalone: true,
  imports: [ImagePipe, LocalizedDatePipe, CommentFormComponent, FontAwesomeModule, MaterialModule, RouterModule,
    TranslateModule, CommonModule]
})
export class CommentComponent implements OnInit {
  @Input() comment!: CommentInterface;
  @Input() activeComment!: ActiveCommentInterface | null;
  @Input() replies!: CommentInterface[];
  @Input() commentedById!: number;
  @Input() commentableId!: number | null;
  @Input() commentableType!: string | null;

  @Output()
  setActiveComment = new EventEmitter<ActiveCommentInterface | null>();
  @Output()
  addComment = new EventEmitter<{ text: string; commentableId: number, commentableType: string }>();
  @Output()
  updateComment = new EventEmitter<{ text: string; commentId: number, commentableType: string }>();
  @Output()
  deleteComment = new EventEmitter<number>();
  @Output()
  reportComment = new EventEmitter<number>();

  credential: Credential;
  createdAt: string = '';
  canReply: boolean = false;
  canEdit: boolean = false;
  canDelete: boolean = false;
  activeCommentType = ActiveCommentTypeEnum;
  replyId: number; //  | null = null;
  replyType: string; //  | null = null;

  constructor(
    private accountService: AccountService,
    public deviceInfo: DeviceInfoService
  ) {
    this.credential = this.accountService.credentialValue;
  }

  ngOnInit(): void {
     const fiveMinutes = 300000;
    const timePassed =
      new Date().getMilliseconds() -
        new Date(this.comment.createdAt).getMilliseconds() >
      fiveMinutes;
    this.createdAt = new Date(this.comment.createdAt).toLocaleDateString();
    this.canReply = true ; // Boolean(this.commentedById);
    this.canEdit = this.credential.profileId==this.comment.commentedById && !timePassed;
    this.canDelete = this.credential.profileId==this.comment.commentedById &&
      this.replies.length==0 && !timePassed;
    this.replyId = this.commentableId ? this.commentableId : this.comment.id;
    // this.replyId = this.comment.commentableId;
    this.replyType = 'comment'; // this.comment.commentableType;
  }

  isReplying(): boolean {
    if (!this.activeComment) {
      return false;
    }
    return (
      this.activeComment.id==this.comment.id &&
      this.activeComment.type==this.activeCommentType.replying
    );
  }

  isEditing(): boolean {
    if (!this.activeComment) {
      return false;
    }
    return (
      this.activeComment.id==this.comment.id &&
      this.activeComment.type=='editing'
    );
  }
}
