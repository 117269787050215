import { Injectable, Inject } from '@angular/core';
import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { Observable } from 'rxjs';
import { v4 as uuidv4 } from 'uuid';
import { DOCUMENT } from '@angular/common';

import { environment } from '@environments/environment';
import { DeviceInfoService } from '@core/services/device-info.service';

@Injectable()
export class IdentInterceptor implements HttpInterceptor {
  constructor(
    private cookieService: SsrCookieService,
    private deviceInfo: DeviceInfoService,
    @Inject(DOCUMENT) private document: Document
  ) {}

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    let ident = this.cookieService.get('Ident');
    if (!ident) {
      ident = uuidv4();
      this.cookieService.set('Ident', ident);
    }
    let credential = this.cookieService.get('credential');
    if (credential) {
      ident = ident + "," + JSON.parse(credential).profileId;
    }
    request = request.clone({
      setHeaders: {
        "X-Ident": ident,
        'X-Href': this.document.location.href,
        'X-Track':  JSON.stringify(this.deviceInfo.getDeviceInfo())
      }
    });
    return next.handle(request);
  }
}
