import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpParams } from '@angular/common/http';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { Subject } from 'rxjs';

import { ApiService } from '@core/services/api.service';
import { environment } from '@environments/environment';
import { Credential } from '@modules/account/models/credential.model';
import { Account } from '@modules/account/models/account.model';
import { Login } from '@modules/login/models/login.model';
import { ProfileService } from '@modules/profile/services/profile.service';
import { ColorSchemeService } from '@core/services/color-scheme.service';
import { StateStorageService } from '@core/services/state-storage.service';

@Injectable({
  providedIn: 'root'
})
export class AccountService {
  public credential: Observable < Credential > ;
  private credentialSubject: BehaviorSubject <any> ;

  constructor(
    private http: HttpClient,
    private router: Router,
    private translate: TranslateService,
    private stateStorageService: StateStorageService,
    private colorSchemeService: ColorSchemeService,
    private apiService: ApiService,
    private profileService: ProfileService,
    private cookieService: SsrCookieService
  ) {
    let credential = this.cookieService.check('credential') ? JSON.parse(cookieService.get('credential')) : null;
    this.credentialSubject = new BehaviorSubject<Credential>(credential);
    this.credential = this.credentialSubject.asObservable();
  }

  getAccounts(params: HttpParams): Observable<any> {
    return this.apiService.get('/accounts', params);
  }

  public get credentialValue(): Credential {
    return this.credentialSubject.value;
  }

  activateStep1(activationKey: string): Observable<any> {
    const params = new HttpParams().set('activationKey', activationKey);
    return this.apiService.get('/activate', params);
  }

  activateStep2(account: any): Observable<any> {
    var subject = new Subject<boolean>();
    let credential = this.getCredentialObject(account);
    this.credentialSubject.next(credential);  // store credential is necessary to save the profile
    this.profileService.saveProfile(account.profile).subscribe((resp) => {
      credential.profileId = resp.data.id;
      credential.slug = resp.data.slug;
      credential.rememberMe = false;
      this.cookieService.set('credential', JSON.stringify(credential), 999, '/');
      this.translate.use(account.langKey);
      this.cookieService.set('locale', account.langKey, 999, '/');
      this.credentialSubject.next(credential);
      subject.next(true);
    });
    return subject.asObservable();
  }

  socialAuth(socialAuthObj: any): Observable<any> {
    return this.apiService.post('/social/auth', socialAuthObj);
  }

  authenticate(account: any): Observable<any> {
    let credential = this.getCredentialObject(account);
    credential.rememberMe = false;
    this.cookieService.set('credential', JSON.stringify(credential), 999, '/');
    this.credentialSubject.next(credential);
    this.translate.use(account.langKey);
    this.cookieService.set('locale', account.langKey, 999, '/');
    return of(true);
  }

  login(login: Login): Observable < void > {
    const path = environment.apiUrl + '/login';
    return this.http.post<any> (path, login).pipe(map(account => {

      // login successful if there's a jwt token in the response
      if (account.accessToken) {

        // After retrieve the account info, the language will be changed to
        // the account's preferred language configured in the account setting
        // unless account's have choosed other language in the credential session
        if (!this.cookieService.get('locale') && account) {
          this.translate.use(account.langKey);
          this.cookieService.set('locale', account.langKey, 999, '/');
        }

        if (!this.cookieService.get('theme')) {
          this.cookieService.set('theme', 'light-theme', 999, '/');
        }
        else {
          this.colorSchemeService.update(this.cookieService.get('theme'));
        }

        let credential = this.getCredentialObject(account);
        this.cookieService.set('credential', JSON.stringify(credential), 999, '/');
        this.credentialSubject.next(credential);

        this.navigateToStoredUrl();
       }
    }));
  }

  logout(): void {
    this.cookieService.delete('credential', '/');
    this.credentialSubject.next(null);
  }

  isAuthenticated(): Observable < Credential | null > {
    return this.credential;
  }

  getAuthenticationState(): Observable < Credential | null > {
    return this.credential;
  }

  getCredentialObject(account: Account) {
    let credential:any = {};
    credential.accountId =  account!.id;
    credential.profileId = account.profile.id!;
    credential.firstName = account.profile.firstName!;
    credential.lastName = account.profile.lastName!;
    credential.slogan = account.profile.slogan!;
    credential.slug = account.profile.slug!;
    credential.photoImage = account.profile.photoImage!;
    credential.login =  account.login!;
    credential.accessToken = account.accessToken!;
    credential.roleId = account.roleId!;
    credential.preference = account.preference!;
    return credential;
  }

  setCredentialSubject(credential: any) {
    this.cookieService.set('credential', JSON.stringify(credential), 999, '/');
    this.credentialSubject.next(credential);
  }

  hasAnyAuthority(authorities: string[] | string): boolean {
    /* if (!this.account) {
      return false;
    }
    if (!Array.isArray(authorities)) {
      authorities = [authorities];
    }
    return this.account.authorities.some((authority: string) => authorities.includes(authority));*/
    return true;
  }

  /* authenticateSuccess(account: Account, rememberMe: boolean): void {
    if (!rememberMe) {
      this.cookieService.clear('account');
    }
  }*/

  savePassword(accountId: any, newPassword: string): Observable<{}> {
    return this.apiService.post('/password/change', { accountId, newPassword });
  }

  recover(email: string): Observable<any> {
    return this.apiService.post('/account/recover', {email});
  }

  reset(resetKey: string): Observable<{}> {
    return this.apiService.get('/password/reset', new HttpParams().set('resetKey', resetKey));
  }

  save(account: any): Observable<{}> {
    return this.apiService.post('/account/save', account);
  }

  markToClose(accountId: any, reason: any): Observable<{}> {
    return this.apiService.post('/account/mark-to-close', {accountId, reason});
  }

  revertClose(accountId: any): Observable<{}> {
    return this.apiService.post('/account/revert-close', {accountId});
  }

  check(accountId: any): Observable<{}> {
    return this.apiService.get('/account/check', new HttpParams().set('my', accountId));
  }

  close(accountId: any): Observable<{}> {
    return this.apiService.post('/account/close', {accountId});
  }

  /* getPayments(params: HttpParams): Observable<any> {
    return this.apiService.get('/account/payments', params);
  }

  savePayments(payment: any): Observable<{}> {
    return this.apiService.post('/account/payment', payments);
  }*/

  private navigateToStoredUrl(): void {
    // previousState can be set in the authExpiredInterceptor and in the userRouteAccessService
    // if login is successful, go to stored previousState and clear previousState
    const previousUrl = this.stateStorageService.getUrl();
    if (previousUrl) {
      this.stateStorageService.clearUrl();
      this.router.navigateByUrl(previousUrl);
    }
  }
}
