/* eslint-disable no-console */
import { Injectable } from '@angular/core';
import { environment } from './../../../environments/environment';

export interface ILoggerService {
    log(message?: any, ...optionalParams: any[]): void;
}

@Injectable()
export class Logger implements ILoggerService {
    log(message?: any, ...optionalParams: any[]): void {
        if (!environment.production) {
            console.log(message, ...optionalParams);
        }
    }
}