import { Pipe, PipeTransform } from '@angular/core';
import { DateTime } from 'luxon';

@Pipe({
  name: 'localizedDate',
  standalone: true,
  pure: false
})
export class LocalizedDatePipe implements PipeTransform {
  constructor() {}

  /* https://github.com/moment/luxon/blob/master/docs/formatting.md */
  transform(value: string, pattern: string = 'DATE_SHORT'): any {
    return DateTime.fromISO(value).toLocaleString((<any>DateTime)[pattern])
  }
}
