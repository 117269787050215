import { NgModule, DEFAULT_CURRENCY_CODE } from '@angular/core';
import { CommonModule, CurrencyPipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { NgxPaginationModule } from 'ngx-pagination';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { ImageCropperModule } from 'ngx-image-cropper';
import { NgxMatSelectSearchModule } from 'ngx-mat-select-search';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { SsrCookieService } from 'ngx-cookie-service-ssr';
import { NgxChartsModule } from '@swimlane/ngx-charts';
import { provideNgxStripe } from 'ngx-stripe';
import { FileSaverModule } from 'ngx-filesaver';
import { PdfJsViewerModule } from "ng2-pdfjs-viewer";

@NgModule({
  exports: [
	  FormsModule,
	  CommonModule,
	  RouterModule,
	  InfiniteScrollModule,
	  FontAwesomeModule,
	  ReactiveFormsModule,
	  TranslateModule,
	  NgxChartsModule,
	  NgxPaginationModule,
	  AngularEditorModule,
	  DragDropModule,
	  ImageCropperModule,
	  NgxMatSelectSearchModule,
	  PickerModule,
	  FileSaverModule,
	  PdfJsViewerModule
  ],
  providers: [
    CurrencyPipe,
    { provide: DEFAULT_CURRENCY_CODE, useValue: '' },
		SsrCookieService,
		provideNgxStripe('pk_test_51ORs5YA4bvDlOEAFpphHFPEPzLl4FrCbpvmuqVJ2aFwbic6d4J7ZROPFLLtnN6z9q7ZaUrcf60ImwhslDVFlc0f600dcbTyoA1')
  ]
})
export class SharedLibsModule {}
