import { Component, EventEmitter, Input, Output, OnInit } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule, ActivatedRoute } from '@angular/router';
import { MatDialog, MatDialogConfig } from "@angular/material/dialog";
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { HttpParams } from '@angular/common/http';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { DateTime } from 'luxon';

import { MaterialModule } from '@shared/material.module';
import { AccountService } from '@modules/account/services/account.service';
import { Credential } from '@modules/account/models/credential.model';
import { Profile } from '@modules/profile/models/profile.model';
import { PostService } from '@modules/post/services/post.service';
import { ImagePipe } from '@core/util/image.pipe';

import { ProcessPostComponent } from './process-post.component';

@Component({
  selector: '[xa-begin-post]',
  templateUrl: './begin-post.component.html',
  styleUrls: ['./begin-post.component.scss'],
  standalone: true,
  imports: [ImagePipe, TranslateModule, FontAwesomeModule, MaterialModule, RouterModule, CommonModule]
})
export class BeginPostComponent implements OnInit {
  @Input() data: any;
  @Output() newPost = new EventEmitter();
  credential: Credential;
  modPost: any;
  forums: any;
  authorId: number;
  groupId: number;
  organizationId: number;
  memStat: any;

  constructor(
    private accountService: AccountService,
    private dialog: MatDialog,
    private postService: PostService,
    private translate: TranslateService,
  ) {}

  ngOnInit(): void {
    this.credential = this.accountService.credentialValue;
  }

  createPost(): any {
    this.data.state = 'new';
    const dialogConfig = new MatDialogConfig();
    dialogConfig.disableClose = false;
    dialogConfig.autoFocus = false;
    dialogConfig.width = "100%";
    dialogConfig.maxWidth = "600px";
    dialogConfig.data = this.data;
    const dialogRef = this.dialog.open(ProcessPostComponent, dialogConfig);

    dialogRef.afterClosed().subscribe(post => {
      if (post) {
        post.text = post.text.replace(/\n|\r\n|\r/g, '<br/>');
        if (post.forumId && this.data.forums) {
          post.forum = this.data.forums.filter((forum: any) => forum.id==Number(post.forumId))[0];
        }
        const author: any = {
          id: this.credential.profileId,
          firstName: this.credential.firstName,
          lastName: this.credential.lastName,
          slug: this.credential.slug,
          slogan: this.credential.slogan,
          photoImage: this.credential.photoImage
        }
        post.author = author;
        post.postedAt = DateTime.now().toISO();
        post.comments = [];
        this.newPost.emit(post)
      }
    });
  }

}
