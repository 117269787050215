<div mat-dialog-title class="dialog-title">
  <h2>Beitrag geteilt</h2>
  <button mat-icon-button class="close-button" [mat-dialog-close]="true">
    <fa-icon icon="xmark" class="xmark"></fa-icon>
  </button>
</div>
<div class="list-item like-item" *ngFor="let share of shares">
  <a routerLink="/profile/{{share.sharedBy.slug}}">
    <img class="avatar" [src]="share.sharedBy.photoImage | image: 'avatar':'s1'" title="{{share.sharedBy.firstName}} {{share.sharedBy.lastName}}" alt="{{share.sharedBy.firstName}} {{share.sharedBy.lastName}}" />
  </a>
  <div class="flex-column">
    <a routerLink="/profile/{{share.sharedBy.slug}}" [state]="{profileId: share.sharedBy.id}">
      <span matLine class="name-item">{{share.sharedBy.firstName}} {{share.sharedBy.lastName}}</span>
    </a>
    <span matLine *ngIf="share.sharedBy.slogan">{{share.sharedBy.slogan}}</span>
  </div>
</div>
