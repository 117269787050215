import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';

import { ApiService } from '@core/services/api.service';
import { Message } from '@modules/message/models/message.model';
import { Recipient } from '@modules/message/models/recipient.model';

@Injectable({
  providedIn: 'root'
})

export class MessageService {
  private messageSource = new BehaviorSubject(new Message());
  private credentialMessage = this.messageSource.asObservable();
  private sendToSource = new BehaviorSubject<Recipient | null>(null);   // (new Recipient());
  public sendTo = this.sendToSource.asObservable();
  private threadSource = new BehaviorSubject<any>('');
  public currentThread = this.threadSource.asObservable();

  constructor(
    private apiService: ApiService
  ) { }

  getThreadId(params : HttpParams): Observable<any> {
    return this.apiService.get('/messages/thread/id', params);
  }

  getAllThreads(params : HttpParams): Observable<any> {
    return this.apiService.get('/messages/threads', params);
  }

  getAllMessagesOfThread(threadId: string, params : HttpParams): Observable<any> {
    const url = `/messages/thread/${threadId}`;
    return this.apiService.get(url, params);
  }

  getAllMessagesOfParticipants(params : HttpParams): Observable<any> {
    const url = `/messages/participants`;
    return this.apiService.get(url, params);
  }

  public setMessage(message: Message) : void {
    this.messageSource.next(message);
  }

  saveMessage(message: any): Observable<any> {
    return this.apiService.post('/messages/save', message);
  }

  getUnreadedMessages(params : HttpParams): Observable<any> {
    return this.apiService.get('/messages/unreaded', params);
  }

  setRecipient(recipient: any) {
    this.sendToSource.next(recipient)
  }

  setArchiveStatus(status: any): Observable<any> {
    return this.apiService.post('/messages/thread/archive', status);
  }

  deleteThread(thread: any): Observable<any> {
    return this.apiService.post('/messages/thread/delete', thread);
  }

  setCurrentThreadId(threadId: any): void {
    this.threadSource.next(threadId);
  }
}
