import { Inject, Injectable } from '@angular/core';
import { Meta, MetaDefinition, Title } from '@angular/platform-browser';
import { Router } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ApiService } from '@core/services/api.service';
import { environment } from '@environments/environment';

export interface PageMetadata {
  title: string;
  imageRelativeUrl: string;
  description: string;
  type: string;
}

const defaultMetadata: PageMetadata = {
  title: 'xaneda',
  imageRelativeUrl: '/assets/images/xaneda.jpg',
  description: 'xaneda',
  type: 'website',
};

@Injectable()
export class MetadataService {
  constructor(
    private apiService: ApiService,
    private metaTagService: Meta,
    private titleService: Title,
    private router: Router
  ) {}

  getMetaDataOfUrl(params : HttpParams): Observable<any> {
    return this.apiService.get('/metadata', params);
  }

  public updateMetadata(metadata: Partial<PageMetadata>, index: boolean = true): void {
    const pageMetadata: PageMetadata = {...defaultMetadata, ...metadata};
    const metatags: MetaDefinition[] = this.generateMetaDefinitions(pageMetadata);

    this.metaTagService.addTags([
     ...metatags,
     { property: 'og:url', content: `${environment.appUrl}${this.router.url}`},
     { name: 'robots', content: index ? 'index, follow' : 'noindex' },
     /* { name: 'viewport', content: 'width=device-width, initial-scale=1' },
     { 'http-equiv': 'Content-Type', content: 'text/html; charset=utf-8' },*/
    ], false);

    this.titleService.setTitle(pageMetadata.title.concat(" | xaneda"));
  }

  public updateTitle(title: string): void {
    this.titleService.setTitle(title.concat(" | xaneda"));
  }

  private generateMetaDefinitions(metadata: PageMetadata): MetaDefinition[] {
    return [
      { name: 'title', content: `${metadata.title} | xaneda` },
      { property: 'og:title', content: `${metadata.title} | xaneda` },
      // { property: 'twitter:title', content: `${metadata.title} | xaneda` },

      { name: 'description', content: metadata.description },
      { property: 'og:description', content: metadata.description },
      // { property: 'twitter:title', content: `${metadata.title} | xaneda` },

      { property: 'og:type', content: metadata.type },

      { property: 'og:image', content:
          metadata.imageRelativeUrl.startsWith("/assets/images") ?
          `${environment.appUrl}${metadata.imageRelativeUrl}` :
          `${environment.cdnUrl}${metadata.imageRelativeUrl}`
      }
      // { property: 'twitter:image', content: `${environment.cdnUrl}${metadata.imageRelativeUrl}`},

      // { property: 'twitter:card', content: 'summary' }
      // { property: 'twitter:site', content: '@xaneda' }
    ];
  }
}
