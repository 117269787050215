import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable } from 'rxjs';

import { ApiService } from '@core/services/api.service';
import { Forum } from '@modules/post/models/forum.model';
import { ObjectOrder } from '@modules/objectOrder.model';

@Injectable({
  providedIn: 'root'
})

export class ForumService {
  constructor(
    private apiService: ApiService
  ) { }

  getForums(params : HttpParams): Observable<any> {
   return this.apiService.get("/forums", params);
  }

  save(forum: Forum): Observable<{}> {
    return this.apiService.post('/forums/save', forum);
  }

  saveForumOrder(forumOrder: ObjectOrder): Observable<{}> {
    return this.apiService.post('/forums/saveForumOrder', forumOrder);
  }

  delete(params: HttpParams): Observable<any> {
    return this.apiService.delete('/forums/delete/', params);
  }

  checkForPosts(params: HttpParams): Observable<any> {
    return this.apiService.get('/forums/checkforposts/', params);
  }
}
