<div class="chip-panel sel-zero" *ngIf="selectedProfiles.length==0">
  <input #input class="chip-input" placeholder="{{'message.searchbar.placeholder' | translate}}" (keyup)="getProfiles($event)">
</div>
<div *ngIf="selectedProfiles.length>0">
  <mat-chip-grid class="chip-panel sel-mult" #chipList>
    <mat-chip-row style="margin-top:0.4em;" *ngFor="let profile of selectedProfiles" (removed)="removeProfile(profile)">
      <span id="{{profile.id}}">{{profile.firstName}} {{profile.lastName}}</span>
      <fa-icon icon="xmark" class="xmark" matChipRemove></fa-icon>
    </mat-chip-row>
    <input #input class="chip-input" (keyup)="getProfiles($event)" [matChipInputFor]="chipList">
  </mat-chip-grid>
</div>
<div class="filtered-profiles" *ngIf="filteredProfiles.length > 0">
  <div class="filtered-item" *ngFor="let profile of filteredProfiles" (click)="selectProfile(profile)">
    <div class="avatar">
      <img [src]="profile.photoImage! | image: 'avatar':'s1'" />
    </div>
    <div class="name-slogan">
      <span class="name-line">{{profile.firstName}} {{profile.lastName}}</span>
      <span class="slogan-line">{{profile.slogan}}</span>
    </div>
  </div>
</div>
