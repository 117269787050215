<div class="panel-container" *ngIf="visits">
  <h5 *ngIf="limit">{{'visit.showAll.header1' | translate}}</h5>
  <h5 *ngIf="!limit">{{'visit.showAll.header2' | translate}}</h5>
  <div class="list">
    <div class="list-item" [ngClass]="{'visit-item': limit===false}" *ngFor="let visit of visits | paginate : {
     itemsPerPage: pageSize, currentPage: page, totalItems: totalItems };">
      <a routerLink="/profile/{{visit.visitor.slug}}">
        <img class="avatar" [src]="visit.visitor.photoImage | image: 'avatar':'s1'"
             title="{{visit.visitor.firstName}} {{visit.visitor.lastName}}"
             alt="{{visit.visitor.firstName}} {{visit.visitor.lastName}}" />
      </a>
      <div class="flex-column">
        <a routerLink="/profile/{{visit.visitor.slug}}">
          <span matLine class="name-item">{{visit.visitor.firstName}} {{visit.visitor.lastName}}</span>
        </a>
        <span matLine>{{visit.visitAt | localizedDate: 'DATETIME_SHORT' }}</span>
      </div>
    </div>
  </div>
  <a routerLink="/visits" class="nav-link mt-20" *ngIf="limit">{{'visit.showAll.link' | translate}}</a>
  <div *ngIf="!limit && totalItems > 10">
    <pagination-controls previousLabel="{{'global.pagination.previous' | translate}}" nextLabel="{{'global.pagination.next' | translate}}"
                          (pageChange)="handlePageChange($event)"></pagination-controls>
  </div>
</div>
