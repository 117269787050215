import { Injectable } from '@angular/core';
import { SsrCookieService } from 'ngx-cookie-service-ssr';

@Injectable({ providedIn: 'root' })
export class StateStorageService {

  constructor(
    private cookieService: SsrCookieService
  ) {}

  storeUrl(url: string): void {
    this.cookieService.set("previousUrl", url);
  }

  getUrl(): string | null {
    return this.cookieService.get("previousUrl") as string | null;
  }

  clearUrl(): void {
    this.cookieService.delete("previousUrl");
  }
}
