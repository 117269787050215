import { Injectable } from '@angular/core';
import { HttpParams } from '@angular/common/http';
import { Observable, of } from 'rxjs';
import { map } from 'rxjs/operators';

import { ApiService } from '@core/services/api.service';
import { Group } from '@modules/group/models/group.model';
import { Member } from '@modules/member/models/member.model';

@Injectable({
  providedIn: 'root'
})
export class GroupService {

  constructor(
    private apiService: ApiService
  ) { }

  getGroups(params : HttpParams): Observable<any> {
    return this.apiService.get('/groups', params);
  }

  getGroup(id: number, seo: boolean, params?: HttpParams): Observable<any> {
    const url = seo == true ? `/groups/seo/${id}` : `/groups/${id}`;
    return this.apiService.get(url, params);
  }

  saveGroup(group: Group): Observable<any> {
    return this.apiService.post('/groups/save/', group);
  }

  getContactsToInvite(slug: string, params: any): Observable<any> {
    const url = `/groups/${slug}/contacts-to-invite`;
    return this.apiService.get(url, params);
  }

  sendInvitation(invitation: any): Observable<any> {
    return this.apiService.post('/groups/invite', invitation);
  }
}
