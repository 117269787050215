import { Component, Inject, OnInit, AfterViewInit, Renderer2, ElementRef, ViewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { HttpParams } from '@angular/common/http';
import { TranslateModule, TranslateService } from '@ngx-translate/core';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { UntypedFormBuilder, Validators } from '@angular/forms';
import { PickerModule } from '@ctrl/ngx-emoji-mart';
import { Emoji } from '@ctrl/ngx-emoji-mart/ngx-emoji';
import { PdfJsViewerModule } from "ng2-pdfjs-viewer";

import { MaterialModule } from '@shared/material.module';
import { AccountService } from '@modules/account/services/account.service';
import { Credential } from '@modules/account/models/credential.model';
import { ProfileService } from '@modules/profile/services/profile.service';
import { Profile } from '@modules/profile/models/profile.model';
import { PostSettingsComponent } from '@modules/post/components/post-settings.component';
import { PostService } from '@modules/post/services/post.service';
import { Post } from '@modules/post/models/post.model';
import { Settings } from '@modules/post/models/settings.model'
import { ImagePipe } from '@core/util/image.pipe';
import { MediaComponent } from '@modules/post/components/media/media.component';
import { DocumentComponent } from '@modules/post/components/document/document.component';
import { PreviewLinkComponent } from '@shared/preview-link/preview-link.component';
import { ContenteditableValueAccessor } from '@shared/contenteditable/contenteditable-value-accessor';
import { DocumentPipe } from '@core/util/document.pipe';
import { FileUploadService } from '@core/services/file-upload.service';
import { environment } from '@environments/environment';

@Component({
  selector: 'xa-process-post',
  templateUrl: './process-post.component.html',
  styleUrls: ['./process-post.component.scss'],
  standalone: true,
  imports: [PdfJsViewerModule, PostSettingsComponent, PreviewLinkComponent, ImagePipe, MediaComponent, DocumentComponent,
  PickerModule, ContenteditableValueAccessor, FormsModule, ReactiveFormsModule, TranslateModule, FontAwesomeModule,
  MaterialModule, RouterModule, CommonModule, DocumentPipe]
})
export class ProcessPostComponent implements OnInit, AfterViewInit {
  @ViewChild('text') inputElement: ElementRef;
  isSubmitted: boolean = false;
  viewable: string = 'post';
  form: any;
  id: any;
  text: any;
  profile: Profile;
  credential: Credential;
  modPost: any;
  forums: any;
  authorId: number;
  groupId: number;
  organizationId: number;
  memStat: any;
  origin: string;
  showEmojiPicker = false;
  numImages: any;
  state: string;
  sets = [
    'native',
    'google',
    'twitter',
    'facebook',
    'emojione',
    'apple',
    'messenger'
  ]
  set: Emoji['set'] = 'twitter';
  images: any;
  video: any;
  document: any;
  settings: Settings = {};
  progressInfos: any[] = [];
  files: any = [];
  fldTextValue: string;
  postObj: any

  constructor(
    private accountService: AccountService,
    private profileService: ProfileService,
    private formBuilder: UntypedFormBuilder,
    private postService: PostService,
    private uploadService: FileUploadService,
    private renderer: Renderer2,
    private dialogRef: MatDialogRef < ProcessPostComponent > ,
    @Inject(MAT_DIALOG_DATA) public data: any
  ) {
    this.state = data.state;
    if (this.state == 'edit') {
      this.handleFormDataStateEdit(data);
    } else {
      this.handleFormDataStateNew(data);
    }
  }

  handleFormDataStateNew(data: any): void {
    this.origin = data.origin;
    if (this.origin == 'group') {
      this.forums = this.data.forums;
      this.memStat = this.data.memStat;
      this.form = this.formBuilder.group({
        id: [''],
        subject: ['', [Validators.required, Validators.minLength(10)]],
        text: ['', [Validators.required, Validators.minLength(10)]],
        visibility: [],
        modPost: ['', []],
        forumId: ['', [Validators.required]],
        groupId: [this.data.originId],
        authorId: [this.data.authorId],
        activityId: [''],
        metadata: [null]
      });
      this.form.get('visibility').setValue('g');
    } else if (this.origin == 'organization') {
      this.form = this.formBuilder.group({
        id: [''],
        text: ['', [Validators.required, Validators.minLength(10)]],
        visibility: [],
        organizationId: [this.data.originId],
        authorId: [this.data.authorId],
        activityId: [''],
        metadata: []
      });
      this.form.get('visibility').setValue('g');
    } else {
      this.form = this.formBuilder.group({
        id: [''],
        subject: [''],
        text: ['', [Validators.required, Validators.minLength(10)]],
        visibility: ['c'],
        forumId: [],
        groupId: [],
        organizationId: [],
        authorId: [this.data.authorId],
        activityId: [''],
        metadata: []
      });
      this.settings.visibility = 'c';
    }
  }

  handleFormDataStateEdit(data: any): void {
    this.origin = data.origin;
    // data.post.text = data.post.text.replace(/<url>(.*?)<\/url>/g, '$1');
    if (this.origin == 'group') {
      this.forums = data.forums;
      this.form = this.formBuilder.group({
        id: [data.post.id, []],
        slug: [],
        subject: [data.post.subject, [Validators.required, Validators.minLength(10)]],
        text: [data.post.text, [Validators.required, Validators.minLength(10)]],
        modPost: [data.post.modPost, []],
        forumId: [data.post.forum.id, [Validators.required]],
        groupId: [data.post.group.id],
        authorId: [data.post.author.id],
        metadata: [data.post.metadata]
      });
    } else if (this.origin == 'organization') {
      this.form = this.formBuilder.group({
        id: [data.post.id, []],
        slug: [],
        text: [data.post.text, [Validators.required, Validators.minLength(10)]],
        organizationId: [data.post.organization.id],
        authorId: [data.post.author.id],
        metadata: [data.post.metadata]
      });
    } else {
      this.form = this.formBuilder.group({
        id: [data.post.id, []],
        slug: [],
        text: [data.post.text, [Validators.required, Validators.minLength(10)]],
        authorId: [data.post.author.id],
        metadata: [data.post.metadata],
        visibility: [data.post.visibility]
      });
      this.settings.visibility = data.post.visibility;
    }
  }

  ngOnInit(): void {
    this.credential = this.accountService.credentialValue;
    this.form.get('text').valueChanges.subscribe((changes: any) => {
      this.fldTextValue = changes;
    })
  }

  ngAfterViewInit(): void {
    this.renderer.listen(this.inputElement.nativeElement, 'paste', (event) => {
      event.preventDefault();
      const div = document.createElement('div');
      div.innerHTML = event.clipboardData.getData('text/plain');
      let pastedText = div.textContent || div.innerText || "";
      if (window.getSelection()) {
        let selection = window.getSelection();
        let range = selection!.getRangeAt(0);
        let span = document.createElement('span');
        span.classList.add('tmp');
        span.innerHTML = pastedText;
        range.deleteContents();
        range.insertNode(span);
        //cursor at the last with this
        range.collapse(false);
        selection!.removeAllRanges();
        selection!.addRange(range);
      }
      let regex = /<\/?span[^>]*>/g
      this.inputElement.nativeElement.innerHTML = this.inputElement.nativeElement.innerHTML.replace(regex, "");
    });
  }

  savePost(): void {
    if (this.form.valid) {
      this.postObj = this.formToObject();
      this.postService.savePost(this.postObj).subscribe((resp) => {
        if (this.settings.groupId) {
          let group: any = {};
          group.id = this.settings.groupId;
          group.name = this.settings.name;
          this.postObj.group = group;
        }
        if (this.settings.organizationId) {
          let organization: any = {};
          organization.id = this.settings.organizationId;
          organization.name = this.settings.name;
          this.postObj.organization = organization;
        }
        if (this.images) {
          const promiseArray = [];
          for (const image of this.images) {
            promiseArray.push(this.upload(image, resp.data.id));
          }
          Promise.all(promiseArray).then(results => {
            this.postObj.files = this.files;
            this.postObj.status = "imageLoading";
            this.dialogRef.close(this.postObj);
          });
        }
        if (this.video) {
          this.upload(this.video, resp.data.id);
          this.postObj.files = this.files; // Referenz wenn Upload beendet
          this.postObj.status = "videoLoading";
          this.dialogRef.close(this.postObj);
        }
        if (this.document) {
           this.upload(this.document, resp.data.id);
           this.postObj.files = this.files;
           this.postObj.status = "documentLoading";
           this.dialogRef.close(this.postObj);
        }
        if (!this.images && !this.video && !this.document) {
          this.postObj.id = resp.data.id;
          this.postObj.activityId = resp.data.activityId;
          this.dialogRef.close(this.postObj);
        }
      })
    }
  }

  upload(file: any, postId: any): void {
    let formData: any = {};
    formData.targetId = postId;
    formData.targetType = 'post';
    formData.originalName = file.name;
    formData.fileType = file.type;
    formData.fileSize = file.size;
    formData.file = file.url;
    this.uploadService.upload(formData).subscribe(resp => {
      this.postObj.files.push({
        url: resp.data.url,
        fileType: resp.data.fileType
      });
    });
  }

  setMetadata(metadata: any) {
    if (metadata) {
      this.form.controls.metadata.setValue(metadata);
    } else {
      this.form.controls.metadata.setValue(null);
    }
  }

  toggleEmojiPicker(): void {
    this.showEmojiPicker = !this.showEmojiPicker;
  }

  /* eslint-disable @typescript-eslint/restrict-template-expressions */
  addEmoji(event: any): void {
    const text = this.form.value.text;
    const textPlusEmoji = text.concat(event.emoji.native);;
    this.form.get("text").setValue(textPlusEmoji);
    this.showEmojiPicker = false;
  }

  onFocus(): void {
    this.showEmojiPicker = false;
  }

  onBlur(): void {
    this.showEmojiPicker = false;
  }

  toggleMedia(): void {
    this.viewable = "media";
  }

  eventHandlerMedia(files: any): void {
    if (files[0].type.startsWith('video')) {
      this.video = files[0];
    }
    else {
      this.images = files;
      this.numImages = this.images.length > 5 ? 5 : this.images.length;
    }
    this.viewable = "post";
  }

  toggleDocument(): void {
    this.viewable = "document";
  }

  eventHandlerDocument(files: any): void {
    this.document = files[0];
    this.viewable = "post";
  }

  togglePostSettings(): void {
    this.viewable = "post-settings";
  }

  eventHandlerSettings(event: any): void {
    this.settings = event;
    this.form.controls.visibility.setValue(this.settings.visibility);
    let groupId = this.settings.groupId ? this.settings.groupId : null;
    this.form.get('groupId').setValue(groupId);
    let forumId = this.settings.forumId ? this.settings.forumId : null;
    this.form.get('forumId').setValue(forumId);
    let organizationId = this.settings.organizationId ? this.settings.organizationId : null;
    this.form.get('organizationId').setValue(organizationId);
    this.viewable = "post";
  }

  formToObject(): any {
    Object.keys(this.form.controls).map((key) => {
      const control = this.form.get(key);
      if (control.value !== undefined && control.value != null && control.value.length == 0) {
        control.setValue(null);
      }
    });
    const obj: any = {};
    obj.id = this.form.value.id;
    obj.slug = this.form.value.slug;
    obj.subject = this.form.value.subject;
    let regex = /<div>(.*?)<\/div>/g;
    obj.text = this.form.value.text.replace(regex, "$1<br>")
    obj.modPost = this.form.value.modPost;
    obj.forumId = this.form.value.forumId;
    obj.groupId = this.form.value.groupId;
    obj.authorId = this.form.value.authorId;
    obj.metadata = this.form.value.metadata;
    obj.visibility = this.form.value.visibility;
    return obj;
  }
}
